import Repository from '@/repositories/RepositoryFactory';
import RegionModel from '@/repositories/v2/Region';

const Region = Repository.get('client', 'region');
const RegionV1 = Repository.get('client', 'regionV1');

export default {
	namespaced: true,
	state: {
		regionalOffice: {},
		regionalOffices: [],
		regions: null,
		simplifiedRegions: [],
		regionalOfficeBasic: [],
		regionalOfficeAdvanced: [],
		regionalOfficeUsers: [],
		loadingSecond: false,
		loadingFirst: false,
		loadingThird: false,
	},
	getters: {

	},
	mutations: {
		LOAD_REGIONS: (state, payload) => {
			state.regions = payload.data;
		},
		LOAD_REGIONAL_OFFICES: (state, payload) => {
			state.regionalOffices = payload.data;
		},
		LOAD_REGIONAL_OFFICE: (state, payload) => {
			state.regionalOffice = payload.data[0];
		},
		LOAD_REGIONAL_OFFICE_BASIC: (state, payload) => {
			state.regionalOfficeBasic = payload;
		},
		LOAD_REGIONAL_OFFICE_ADVANCED: (state, payload) => {
			state.regionalOfficeAdvanced = payload.data[0];
		},
		LOAD_SIMPLIFIED_REGIONS: (state, payload) => {
			state.simplifiedRegions = payload.data;
		},
		LOAD_USERS: (state, payload) => {
			state.regionalOfficeUsers = payload;
		},
		SET_LOADING_SECOND (state, loading) {
			state.loadingSecond = loading;
		},
		SET_LOADING_FIRST (state, loading) {
			state.loadingFirst = loading;
		},
		SET_LOADING_THIRD (state, loading) {
			state.loadingThird = loading;
		},
	},
	actions: {
		async get ({ commit }) {
			commit('LOAD_REGIONS', await Region.all());
		},
		async getAll ({ commit }, id) {
			commit('LOAD_REGIONAL_OFFICES', await Region.include(['phones', 'addresses']).where('trashed', 'with').where('organization_id', `${id}`).get());
		},
		async getAllRegions ({ commit }, id) {
			commit('LOAD_REGIONAL_OFFICES', await Region.where('trashed', 'with').where('organization_id', `${id}`).get());
		},
		async getAllForSecurityOffice ({ commit }) {
			commit('LOAD_REGIONAL_OFFICES', await Region.include(['phones', 'addresses']).where('trashed', 'with').get());
		},
		async getAllForSecurityOfficeSimplified ({ commit }) {
			commit('LOAD_REGIONAL_OFFICES', await Region.where('trashed', 'with').get());
		},
		async getAllRegionsUser ({ commit }, id) {
			commit('LOAD_REGIONAL_OFFICES', await Region.include(['locations']).where('organization_id', `${id}`).get());
		},
		async getRegionById ({ commit }, id) {
			commit('LOAD_REGIONAL_OFFICE', await Region.include(['phones', 'addresses', 'domains', 'schedules.*', 'defaultSchedule.*']).find(id));
		},
		async getRegionDetails ({ commit }, object) {
			const region = new Region({ id: object.id });
			commit('LOAD_REGIONAL_OFFICE', await Region
				.params({ show_holidays: 1 })
				.custom(region)
				.include('locations.assignments.role', 'locations.assignments.user', 'phones', 'addresses', 'defaultSchedule.*', 'locations.phones', 'locations.addresses', 'organization.users.phones', 'organization.users.addresses', 'assignments.user', 'assignments.role')
				.get());
		},
		async getRegionDetailsBasic ({ commit }, object) {
			const region = await Region
				.params({ show_holidays: 1 })
				.include('phones', 'addresses', 'defaultSchedule.*')
				.$find(object.id);

			commit('LOAD_REGIONAL_OFFICE_BASIC', region);
		},
		async getRegionDetailsAdvanced ({ commit }, object) {
			commit('SET_LOADING_SECOND', true);
			const region = new Region({ id: object.id });
			commit('LOAD_REGIONAL_OFFICE_ADVANCED', await Region
				.params({ show_holidays: 1 })
				.custom(region)
				.include('locations.assignments.role', 'locations.phones', 'locations.addresses')
				.get());
			commit('SET_LOADING_SECOND', false);
		},
		async getRegionDetailsUsers ({ commit }, body) {
			commit('SET_LOADING_THIRD', true);
			commit('LOAD_USERS', await (new RegionModel({ id: body.id })).users().$get());
			commit('SET_LOADING_THIRD', false);
		},
		async getRegionDetailsV2 ({ commit }, object) {
			const region = new Region({ id: object.id });
			const response = await Region
				.params({ show_holidays: 1 })
				.custom(region)
				.include('locations.assignments.role', 'locations.assignments.user', 'phones', 'addresses', 'defaultSchedule.*', 'locations.phones', 'locations.addresses', 'organization.users.phones', 'organization.users.addresses', 'assignments.user', 'assignments.role')
				.get();

			commit('LOAD_REGIONAL_OFFICE', response);
			return response;
		},
		async storeV1 ({ commit }, object) {
			const res = await RegionV1.store(object);
			return res;
		},
		async listForAssignments ({ commit }, orgId) {
			const res = await RegionV1.listForAssignments(orgId);
			return res;
		},
		async store ({ commit }, object) {
			const region = await new Region(object);
			const save = region.save();
			return save;
		},
		async updateV1 ({ commit }, object) {
			const res = await RegionV1.update(object);
			return res;
		},
		async setActiveRegion ({ commit }, object) {
			const res = await RegionV1.setActiveRegion(object);
			return res;
		},
		async delete ({ commit }, object) {
			await RegionV1.delete(object);
		},
		async update ({ commit }, object) {
			const model = object;
			model.save();
		},
		async getSimplifiedRegions ({ commit }, body) {
			const response = await RegionV1.getSimplifiedRegions({
				trashed: 'with',
				organization_id: body.id,
			});
			commit('LOAD_SIMPLIFIED_REGIONS', response);
		},
	},
};
