// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue';
import VueTheMask from 'vue-the-mask';
import VueUnits from 'vue-units';
import vueInternetChecker from 'vue-internet-checker';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VuetifyConfirm from 'vuetify-confirm';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { waitForTests } from './lib/modernizr-checks';
import i18n from './i18n';
import './plugins/base';
import './plugins/vee-validate';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { setupInterceptors } from './plugins/axios';
import { formatterFilter } from './utils/luxon-formater';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
	iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
	iconUrl: require('leaflet/dist/images/marker-icon.png'),
	shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

Vue.config.productionTip = false;

Sentry.init({
	Vue: Vue,
	dsn: process.env.VUE_APP_RAVEN_DSN,
	logErrors: process.env.NODE_ENV !== 'production',
	// performance tracing is 50% in production and 0% in development
	integrations: [new Integrations.BrowserTracing()],
	tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.5 : 0,
	tracingOptions: {
		trackComponents: true,
	},
	// For development, log Sentry Breadcrumbs to console
	beforeBreadcrumb (breadcrumb) {
		if (process.env.NODE_ENV !== 'production' && breadcrumb.category !== 'console') {
			console.log(breadcrumb); // eslint-disable-line no-console
		}
		return breadcrumb;
	},
});

export const EventBus = new Vue();

Vue.use(VueUnits);
Vue.use(VueTheMask);
Vue.use(vueInternetChecker);
Vue.use(VuetifyConfirm, { vuetify });

Vue.filter('dateformat', formatterFilter);

Vue.filter('ellipsize', function (value, maxLength, secure = false) {
	if (value.length > maxLength) {
		return value.substring(0, maxLength) + '...';
	}
	if (secure) {
		return '...';
	}
	return value;
});

const modernizrTests = {
	eventlistener: 'Event Listener Support',
	fetch: 'Fetch API Support',
	flexbox: 'Flexbox Support',
	json: 'JSON Support',
	localstorage: 'LocalStorage Support',
	promises: '"Promise" Support',
	queryselector: 'QuerySelector Support',
	sessionstorage: 'SessionStorage Support',
	svg: 'SVG Graphics Support',
};

waitForTests(modernizrTests).then(() => {
	Sentry.addBreadcrumb({ message: 'Browser Tests passed' });

	window._vm = new Vue({
		router,
		store,
		vuetify,
		i18n,
		render: h => h(App),
		beforeCreate () {
			setupInterceptors(this.$store);
		},
	}).$mount('#app');
}).catch(failures => {
	document.getElementById('app').style.display = 'none';
	document.getElementById('deps-fail').style.display = 'block';
	var list = document.getElementById('failedTestsList');

	failures.forEach(message => {
		var li = document.createElement('li');
		li.innerHTML = message;
		list.appendChild(li);
	});
});
