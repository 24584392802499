<template>
	<router-view />
</template>

<script>
import { debounce } from './lib/form-utils';
import { getCsrfCookie } from '@/repositories/v2/security';

export default {
	name: 'App',

	data: () => ({
	}),
	methods: {
		on_resize () {
			this.$store.commit('SET_SCREEN_SIZE', {
				height:	window.innerHeight,
				width: window.innerWidth,
			});
		},
	},
	async beforeMount () {
		try {
			this.$store.commit('authentication/initialize');

			await getCsrfCookie();

			await this.$store.dispatch('authentication/loadUserData');
		} catch (err) {
			// TODO: How to handle this error?
		}
	},
	async mounted () {
		this.$store.commit('SET_SCREEN_SIZE', {
			height:	window.innerHeight,
			width: window.innerWidth,
		});
		window.addEventListener('resize', debounce(this.on_resize, 250));
	},
	beforeDestroy () {
		window.removeEventListener('resize', this.on_resize);
	},
};
</script>

<style scoped theme="safebanker">
#main-app {
	background: linear-gradient(#557DBE, #3C5886)
}
.v-data-table-header th {
  white-space: nowrap;
}
</style>

<style theme="safebanker">
/* #main-app {
	background: #04f;
} */
	.card-title{
		background: #F3F4F6;
		color: #000;
	}
.v-data-table-header th {
  white-space: nowrap;
}
</style>

<style theme="saferesource">
/* #main-app {
	background: #fa0;
} */
	.card-title{
		background: #F3F4F6;
		color: #000;
	}
.v-data-table-header th {
  white-space: nowrap;
}
</style>
