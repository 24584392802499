import { PRESETS } from '@/utils/dates';

export default {
	namespaced: true,
	state: {
		startDate: PRESETS[0].range[0],
		endDate: PRESETS[0].range[1],
		startTime: '00:00:00',
		endTime: '23:59:59',
		clientId: undefined,
		flag: false,
		getCoordinates: [],
	},
	mutations: {
		UPDATE_FILTERS: (state, payload) => {
			state.startDate = payload.pickerStart;
			state.endDate = payload.pickerEnd;
		},
		UPDATE_FILTERS_CLIENT: (state, payload) => {
			state.clientId = payload;
		},
		UPDATE_FLAG: (state, value) => {
			state.flag = value;
		},
		GET_COORDINATES: (state, value) => {
			state.getCoordinates = value;
		},
	},
	actions: {
		async updateFilters ({ commit }, payload) {
			commit('UPDATE_FILTERS', payload);
		},
		async updateFiltersClient ({ commit }, payload) {
			commit('UPDATE_FILTERS_CLIENT', payload);
		},
	},
	getters: {
		getFilter (state) {
			return {
				startDate: state.startDate,
				endDate: state.endDate,
			};
		},
	},
};
