import { postLogout } from '@/repositories/v2/security';
import store from '../store';

export async function redirectToAuth ({ direction = 'login', path = '', reason }) {
	const thisSite = new URL(window.location);
	if (process.env.VUE_APP_NAVIGATION_MODE === 'history') {
		thisSite.pathname = path;
	} else {
		thisSite.hash = path;
	}

	const goTo = new URL(store.state.authentication.apiServer || process.env.VUE_APP_ACCOUNT_SERVER);
	goTo.pathname = '/auth/login';
	goTo.searchParams.set('redirect', thisSite);

	if (reason) {
		goTo.searchParams.set('reason', reason);
	}

	// To prevent denial-of-service via CSRF, logout uses POST
	if (direction === 'logout' && reason !== 'expired') {
		await postLogout();
	}

	window.location.href = goTo;
}

export function checkCapabilites (to, from, next) {
	if (to.meta?.caps?.length > 0) {
		if (store.getters['authentication/hasAnyCapability'](to.meta.caps)) {
			next();
		} else {
			store.commit('alerts/add', {
				id: 'navAuthFail',
				type: 'error',
				message: 'Navigation permission denied',
			});
			next(false);
		}
		return;
	}
	next();
}

function handleVisibilityChange () {
	if (document.visibilityState === 'hidden') {
		var inactive = new Date().getTime();
		localStorage.lastUpdate = inactive;
	} else {
		var active = new Date().getTime();
		var diff = Math.abs(active - localStorage.lastUpdate);
		if (diff > 60000) {
			// If this call fails, it will redirect to login screen
			store.dispatch('authentication/loadUserData');
		}
	}
}

window.addEventListener('visibilitychange', handleVisibilityChange, false);
