import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { SAFE_BANKER } from '@/themes/safebanker/colors';
import { SAFE_RESOURCE } from '@/themes/saferesource/colors';
import { SAFE_SECURITY } from '@/themes/safesecurity/colors';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: SAFE_RESOURCE,
			dark: SAFE_BANKER,
			security: SAFE_SECURITY,
		},
	},
});
