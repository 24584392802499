export const SAFE_BANKER = {
	primary: '#1976D2',
	secondary: '#F3F4F6',
	accent: '#8c9eff',
	error: '#b71c1c',
	info: '#00CAE3',
	success: '#2ED35D',
	warning: '#FF9800',
	open: '#2ED35D',
	close: '#9E9E9E',
	service: '#FF9800',
	alarm: '#F44336',
};

// export const PRODUCT_THEME = {
// 	primary: '#1976D2',
// 	secondary: '#F3F4F6',
// 	info: '#00CAE3',
// 	accent: '#8c9eff',
// 	error: '#b71c1c',
// 	open: '#4caf50',
// 	close: '#9E9E9E',
// 	service: '#FF9800',
// 	alarm: '#F44336',
// };
