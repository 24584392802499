import { APIServerError, UserError } from '@/lib/Errors';
import $http from '../../plugins/axios';

export async function getLocationsForMap () {
	const response = await $http.get('/api/dashboard/locations');
	if (response.status !== 200) {
		throw new APIServerError('Unable to load locations for map');
	}
	return response.data;
}
export async function readedNotification (obj) {
	const response = await $http.patch(`/api/users/${obj.id}`, obj.body);
	if (response.status !== 200) {
		throw new APIServerError('Unable to make read notification');
	}
	return response.data;
}
export async function getEvents (params) {
	const response = await $http.get('/api/dashboard/events', { params });
	if (response.status !== 200) {
		throw new APIServerError('Unable to load events for dashboard');
	}
	return response.data;
}

export async function getOnlineUsers (organizationId) {
	const response = await $http.get('/api/onlineUsers', { params: { organization_id: organizationId } });
	if (response.status === 200) {
		return response.data;
	} else {
		throw new UserError('Error updating user properties');
	}
}

export async function eventsNotifications (params) {
	const response = await $http.get('/api/dashboard/eventsNotification', { params });
	if (response.status !== 200) {
		throw new APIServerError('Unable to load events for dashboard');
	}
	return response.data;
}
export async function eventsNotificationsNew (params, page) {
	const response = await $http.get(`/api/dashboard/eventsNotification?page=${page}`, { params });
	if (response.status !== 200) {
		throw new APIServerError('Unable to load events for dashboard');
	}
	return response.data;
}

export async function getEventDetails (correlationId) {
	const response = await $http.get('/api/events', { params: { id: correlationId, display: 'detailed' } });
	if (response.status !== 200) {
		throw new APIServerError('Unable to load events for dashboard');
	}
	return response.data;
}
