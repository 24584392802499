<template>
	<div class="mdrp-root" v-clickoutside="clickOutside" style="z-index: 999;">
		<v-menu v-model="menu" offset-y :close-on-content-click="false" bottom>
			<template v-slot:activator="{ on, attrs }">
				<v-btn class="ml-2"
						min-width="0"
						text
						v-bind="attrs" v-on="on">{{ startText }} - {{ endText }}
					<v-icon>mdi-filter</v-icon>
				</v-btn>
			</template>

			<v-card class="v-date-range__menu-content">
				<v-card-text>
					<div class="v-date-range__content">
						<calendar-ranges
							v-if="showPresets && presets"
							:canSelect="inRange"
							:presets="presets"
							:applyLabel="locale.applyLabel"
							:cancelLabel="locale.cancelLabel"
							@clickCancel="pickerVisible = false"
							@clickApply="clickApply"
							@clickPreset="clickPreset"
						></calendar-ranges>
						<v-divider vertical></v-divider>
						<calendar
							class="calendar left mr-4 v-date-range__picker--start v-date-range__picker"
							location="left"
							:calendar-month="leftCalendarMonth_"
							:locale="locale"
							:start="start_"
							:end="end_"
							:hover-start="hoverStart_"
							:hover-end="hoverEnd_"
							:isFirstClick="isFirstClick"
							:from="from"
							:till="till"
							:isBetweenEighteenMonths="isBetweenEighteenMonths"
							:isNotPresentMonthYear="isNotPresentMonthYear"
							@clickNextMonth="clickNextMonth"
							@clickPrevMonth="clickPrevMonth"
							@dateClick="dateClick"
							@hoverDate="hoverDate"
							@clickYearSelect="clickYearSelect"
						></calendar>
						<calendar
							class="calendar right v-date-range__picker--end v-date-range__picker"
							location="right"
							:calendar-month="rightCalendarMonth_"
							:locale="locale"
							:start="start_"
							:end="end_"
							:hover-start="hoverStart_"
							:hover-end="hoverEnd_"
							:isFirstClick="isFirstClick"
							:from="from"
							:till="till"
							:isBetweenEighteenMonths="isBetweenEighteenMonths"
							:isNotPresentMonthYear="isNotPresentMonthYear"
							@clickNextMonth="clickNextMonth"
							@clickPrevMonth="clickPrevMonth"
							@dateClick="dateClick"
							@hoverDate="hoverDate"
							@clickYearSelect="clickYearSelect"
						></calendar>
					</div>
				</v-card-text>
				<v-divider class="mx-10"></v-divider>
			</v-card>
		</v-menu>
	</div>
</template>

<script>
import moment from 'moment';
import Calendar from './Calendar.vue';
import CalendarRanges from './Ranges.vue';
import clickoutside from '../directives/clickoutside';
import { PRESETS } from '@/utils/dates';

export default {
	name: 'v-md-date-range-picker',
	components: {
		Calendar,
		CalendarRanges,
	},
	directives: { clickoutside },
	provide () {
		return {
			picker: this,
		};
	},
	props: {
		// The beginning date of the initially selected date range.
		// If you provide a string, it must match the date format string set in your locale setting
		startDate: {
			type: String,
			default: moment().format('YYYY-MM-DD'),
		},
		// The end date of the initially selected date range.
		endDate: {
			type: String,
			default: moment().format('YYYY-MM-DD'),
		},
		// Set predefined date ranges the user can select from.
		// The range of each object an array with two dates representing the bounds of the range.
		presets: {
			type: Array,
			default () {
				return PRESETS;
			},
		},
		// Whether the picker appears aligned to the left, to the right, or centered under the HTML element it's attached to.
		opens: {
			type: String,
			default: 'left',
		},
		// Displays "Custom Range" at the end of the list of predefined ranges, when the ranges option is used.
		// This option will be highlighted whenever the current date range selection does not match one of the predefined ranges.
		// Clicking it will display the calendars to select a new range.
		showCustomRangeLabel: {
			type: Boolean,
			default: false,
		},
		// Show year select boxes above calendars to jump to a specific year.
		showYearSelect: {
			type: Boolean,
			default: false,
		},
		// The minimum year shown in the dropdowns when showYearSelect is set to true.
		minYear: {
			type: String,
			default: moment().subtract(100, 'year').format('YYYY'),
		},
		// The maximum year shown in the dropdowns when showYearSelect is set to true.
		maxYear: {
			type: String,
			default: moment().add(100, 'year').format('YYYY'),
		},
		// Hide the apply and cancel buttons, and automatically apply a new date range as soon as two dates are clicked.
		autoApply: {
			type: Boolean,
			default: true,
		},
		// show label for the default activator (inputbox)
		showActivatorLabel: {
			type: Boolean,
			default: true,
		},
		// show animation bar for the default activator (inputbox)
		showActivatorBar: {
			type: Boolean,
			default: true,
		},
		showPresets: {
			type: Boolean,
			default: true,
		},
	},
	data () {
		const data = {
			locale: {
				direction: 'ltr',
				format: moment.localeData().longDateFormat('L'),
				separator: ' - ',
				applyLabel: 'Apply',
				cancelLabel: 'Cancel',
				weekLabel: 'W',
				customRangeLabel: 'Custom Range',
				daysOfWeek: moment.weekdaysMin(),
				monthNames: moment.monthsShort(),
				firstDay: moment.localeData().firstDayOfWeek(),
			},
		};
		// https://github.com/ly525/blog/issues/252
		// https://github.com/ly525/blog/issues/258
		data.leftCalendarMonth_ = moment(this.startDate);
		data.rightCalendarMonth_ = moment(this.endDate);
		data.start_ = moment(this.startDate);
		data.end_ = moment(this.endDate);
		data.hoverStart_ = moment(this.startDate);
		data.hoverEnd_ = moment(this.endDate);
		// fix #14
		data.cloneStart = moment(this.startDate);
		data.cloneEnd = moment(this.endDate);

		data.startText = moment(this.startDate).format(data.locale.format);
		data.endText = moment(this.endDate).format(data.locale.format);
		data.inRange = false; // inRange means whether user click once, if user click once, set value true
		data.pickerVisible = false;
		data.tillDateBackword = moment();
		data.tillDateForward = moment();
		data.menu = false;
		data.isFirstClick = true;
		data.from = null;
		data.till = null;
		data.isBetweenEighteenMonths = true;
		data.isNotPresentMonthYear = false;

		// update day names order to firstDay
		if (data.locale.firstDay !== 0) {
			let iterator = data.locale.firstDay;
			while (iterator > 0) {
				data.locale.daysOfWeek.push(data.locale.daysOfWeek.shift());
				iterator--;
			}
		}
		return data;
	},
	methods: {
		clickYearSelect ({
			location,
			calendarMonth,
		}) {
			this[`${location}CalendarMonth_`] = calendarMonth.clone();
		},
		clickNextMonth () {
			const present = moment();
			const currentLeft = this.leftCalendarMonth_;
			if (present.year() !== currentLeft.year() || present.month() !== currentLeft.month()) {
				this.leftCalendarMonth_ = this.leftCalendarMonth_.clone().add(1, 'month');
				this.isBetweenEighteenMonths = true;
			}

			const oneMonthBack = present.clone().subtract(1, 'month');
			if (oneMonthBack.month() === currentLeft.month() && oneMonthBack.year() === currentLeft.year()) {
				this.isNotPresentMonthYear = false;
			}
		},
		clickPrevMonth () {
			const present = moment();
			const eighteenMonthsBack = moment().subtract(18, 'months');
			const currentLeft = this.leftCalendarMonth_;
			if (currentLeft.isBetween(eighteenMonthsBack, present, 'months', '(]')) {
				this.leftCalendarMonth_ = this.leftCalendarMonth_.clone().subtract(1, 'month');
				this.isNotPresentMonthYear = true;
			}

			const seventeenthMonth = eighteenMonthsBack.clone().add(1, 'month');
			if (seventeenthMonth.month() === currentLeft.month() && seventeenthMonth.year() === currentLeft.year()) {
				this.isBetweenEighteenMonths = false;
			}
		},
		/**
		 * TODO type of value
		 */
		dateClick (obj) {
			this.isFirstClick = obj.isFirstClick;
			this.from = obj.from;
			this.till = obj.till;
			const value = obj.date;
			if (this.inRange) { // second click
				this.inRange = false;
				// if second click value is smaller than first, which means user clicked a previous date,
				// so set the smaller date as start date, bigger one as end date
				if (value.isBefore(this.start_)) {
					this.end_ = this.start_;
					this.start_ = value.clone();
				} else {
					this.end_ = value.clone();
				}

				this.menu = false;

				// feature #49
				if (this.autoApply) {
					this.clickApply();
				}
			} else { // first click
				this.tillDateBackword = value.clone().subtract(31, 'days');
				this.tillDateForward = value.clone().add(31, 'days');
				this.inRange = true;
				this.start_ = value.clone();
				this.end_ = value.clone();
				// Notice: If you watch start_, its callback function will be executed after end_ is assigned, which is exactly what we want.
				// You can add a loop to test here
				// In fact, the callback function is actually updateMonthCalendar, which is to update the date based on the values of start and end.
				// So if the callback is callback after both the start_ and end_, that's right!
				// updateMonthCalendar() === callback function for watch start_
			}
		},
		hoverDate (value) {
			if (value < moment()) {
				if (this.inRange) {
					if (value > this.start_) {
						if (value.isBetween(this.start_, this.tillDateForward, 'days', '()')) {
							// 参见：https://github.com/ly525/blog/issues/254
							this.hoverStart_ = this.start_.clone();
							this.hoverEnd_ = value.clone();
						}
					} else {
						if (value.isBetween(this.tillDateBackword, this.start_, 'days', '()')) {
							this.hoverEnd_ = this.start_.clone();
							this.hoverStart_ = value.clone();
						}
					}
				}
			}
		},
		togglePicker () {
			// ---- fix #53 start ----
			let elm = this.$refs.defaultActivator && this.$refs.defaultActivator.$el;
			// fix #55: this.$slots.input[0] -> this.$slots.input[0].elm
			const slotActivator = this.$slots.input && this.$slots.input.length && this.$slots.input[0].elm;
			if (!elm && (slotActivator.querySelector('input') || slotActivator.querySelector('button'))) {
				elm = slotActivator;
			}

			if (elm) {
				// 1. dont return or do nothing here,
				// because you need to show the picker panel if the picker panel is hidden(example: user click the activator first time)
				// but `this.pickerVisible = !this.pickerVisible;` do the samething in this case.
				// So why set pickerVisible always `true` if elm exist?
				// 2. [interact]: because if the type of activator is input or button and the picker panel is already visible (pickerVisible === true),
				// when the user click the activator, the picker panel should keep visible(can not fold the picker panel)

				this.pickerVisible = true;
			} else {
				this.pickerVisible = !this.pickerVisible;
			}
			// ---- fix #53 end ----
		},
		pickerStyles () {
			return {
				'show-calendar': this.pickerVisible,
				'opens-arrow-pos-right': this.opens === 'right',
				'opens-arrow-pos-left': this.opens === 'left',
				'opens-arrow-pos-center': this.opens === 'center',
			};
		},
		clickApply () {
			this.pickerVisible = false;

			// fix #14
			// if the use only click the picker only one time,
			// then close the picker directly(by clickoutside or click the activator)
			if (this.inRange) {
				this.inRange = false;
				this.start_ = this.cloneStart.clone();
				this.end_ = this.cloneEnd.clone();
				return;
			}

			this.updateTextField();
			this.cloneForCancelUsage();
			this.emitChange();
		},
		clickPreset (preset) {
			if (preset.label === this.locale.customRangeLabel) return;
			const [start, end] = preset.range;
			this.start_ = moment(start);
			this.end_ = moment(end);
			// fix #47
			this.leftCalendarMonth_ = moment(start);
			// TODO if linkedCalendar, what should the UI show if end - start > 60 days?

			// feature #49
			if (this.autoApply) {
				this.clickApply();
			}

			this.menu = false;
			this.isBetweenEighteenMonths = true;
			this.isNotPresentMonthYear = this.leftCalendarMonth_.month() < moment().month();
		},
		/**
		 *
		 */
		updateTextField () {
			// do not update the input slot provided content by the parent
			if (this.$slots.input) return;

			this.startText = this.start_.format(this.locale.format);
			this.endText = this.end_.format(this.locale.format);
		},
		/**
		 * fix #14
		 * clone start and end for the following scenes, mainly for reseting the selected date to origin state:
		 * 1. (autoApply: false) click start [or both start and end], but click the cancel button
		 * 2. (autoApply: true) just click one time, and then click outside
		 *
		 * TODO (need discussion) maybe we can do this action in watch pickerVisible (from hidden to visible)
		 * DONE we also need to do clone start and end in the watcher of ther related prop
		 *
		 */
		cloneForCancelUsage () {
			this.cloneStart = this.start_.clone();
			this.cloneEnd = this.end_.clone();
		},
		emitChange () {
			const start = this.start_.clone();
			const end = this.end_.clone();
			this.$emit('change', [start, end], [start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD')]);
		},
		clickOutside () {
			if (!this.pickerVisible) return;
			this.clickApply();
		},
	},
	watch: {
		start_ (value) {
			this.hoverStart_ = value.clone();
			// inspired by https://github.com/dangrossman/daterangepicker/blob/master/daterangepicker.js#L554
			// fix #43
			if (value.month() === this.end_.month()) return;
			this.leftCalendarMonth_ = value.clone();
		},
		end_ (value) {
			this.hoverEnd_ = value.clone();
		},
		leftCalendarMonth_: {
			handler (leftMonth) {
				this.rightCalendarMonth_ = leftMonth.clone().add(1, 'month');
			},
			immediate: true,
		},
		startDate (value) {
			this.start_ = moment(value);
			this.startText = moment(value).format(this.locale.format);
			this.cloneStart = moment(value); // fix #14
		},
		endDate (value) {
			this.end_ = moment(value);
			this.endText = moment(value).format(this.locale.format);
			this.cloneEnd = moment(value); // fix #14
			// TODO not linked calendar
		},
	},
};
</script>
<style lang="scss" scoped>
@import '../styles/components/picker.scss';

.v-date-range__input-field > > > input {
	text-align: center;
}

/* =============================================
=            Menu Content            =
============================================= */
.v-date-range__content {
	display: flex;

	> > > .v-date-picker-table {
		.v-btn {
			border-radius: 0;
		}
	}
}

/* =====  End of Menu Content  ====== */
.v-date-range__pickers > > > .v-date-picker-table__events {
	height: 100%;
	width: 100%;
	top: 0;
	z-index: -1;
}

/* =============================================
=            Date buttons            =
============================================= */
.v-date-range__pickers > > > .v-date-picker-table table {
	width: auto;
	margin: auto;
	border-collapse: collapse;

	& th, & td {
		height: 32px;
		width: 32px;
	}

	& td {
		.v-btn {
			&.v-btn--outline {
				border: none;
				box-shadow: 0 0 0 1px currentColor inset;
			}

			&.v-btn--active::before {
				background-color: transparent !important;
			}
		}
	}
}

/* =====  End of Date buttons  ====== */
/* =============================================
=            Highlighting the even bubble dot            =
============================================= */
.v-date-range__pickers > > > .v-date-range__in-range {
	height: 100%;
	width: 100%;
	margin: 0;
	border-radius: 0;
}

/* =====  End of Highlighting the even bubble dot  ====== */
</style>
