import ChatMessage from '@/lib/models/Client/ChatMessage';
import { ChatMessageError } from '@/lib/Errors';
import $http from '../../../plugins/axios';

export default {
	async getConsoleChat (object) {
		const response = await $http.post('/api/client/startchatso', object);

		if (response.data?.success) {
			const data = response.data.body.sessiondetails;
			const chat = new ChatMessage(data);
			return chat;
		} else {
			throw new ChatMessageError('Error retrieving Chats');
		}
	},
	async sendChatMessage (object) {
		const response = await $http.post('/api/client/writeinchat', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new ChatMessageError('Error sending chat message');
		}
	},
	async answerChatFromConsole (object) {
		const response = await $http.post('/api/client/answerchatfromconsole', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new ChatMessageError('Error answering chat');
		}
	},
	async finishChat (object) {
		const response = await $http.post('/api/client/finishChat', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new ChatMessageError('Error ending chat');
		}
	},
	async holdChat (object) {
		const response = await $http.post('/api/client/holdchat', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new ChatMessageError('Error holding chat');
		}
	},
	async getChatRecordings (object) {
		const response = await $http.get(`/api/reports/chats?start=${object.start}&end=${object.end}&organization_id=${object.organization_id}`, object);
		if (response.status === 200) {
			return response.data;
		} else {
			throw new ChatMessageError('Error retrieving chat list');
		}
	},
	async getUserDetails (id) {
		const response = await $http.get(`/api/users/${id}?include=phones,assignments.role,*,organization`);
		if (response.status === 200) {
			return response.data;
		} else {
			throw new ChatMessageError('Error retrieving chat list');
		}
	},
	async getSessionDetailsBySessionId (item) {
		const response = await $http.get(`/api/reports/chat/${item.correlation_id}/${item.user_id}`);

		if (response.status === 200) {
			return response.data;
		} else {
			throw new ChatMessageError('Error retrieving chat list');
		}
	},
	async getSessionDetailsBySessionIdVideo (item) {
		const response = await $http.get(`/api/reports/video/${item.correlation_id}/${item.user_id}`);

		if (response.status === 200) {
			return response.data;
		} else {
			throw new ChatMessageError('Error retrieving chat list');
		}
	},
};
