import activeSessions from './modules/activeSessions';
import alerts from './modules/alerts';
import authentication from './modules/authentication';
import branches from './modules/branches';
import chat from './modules/chat';
import chatMessages from './modules/chatMessages';
import dashboard from './modules/dashboard';
import filters from './modules/filters';
import headOffice from './modules/headOffice';
import notifications from './modules/notifications';
import organisation from './modules/organisation';
import recordings from './modules/recordings';
import regions from './modules/regions';
import reports from './modules/reports';
import securityOfficers from './modules/securityOfficers';
import securityOffices from './modules/securityOffices';
import securityPhrases from './modules/securityPhrases';
import surveys from './modules/surveys';
import task from './modules/task';
import tasklists from './modules/tasklists';
import timer from './modules/timer';
import users from './modules/users';
import schedules from './modules/schedules';
import socket from './modules/socket';
import product from '@/store/modules/product';

export default {
	alerts,
	regions,
	headOffice,
	branches,
	authentication,
	securityOffices,
	users,
	tasklists,
	surveys,
	securityOfficers,
	chatMessages,
	reports,
	notifications,
	activeSessions,
	filters,
	chat,
	task,
	organisation,
	recordings,
	timer,
	securityPhrases,
	schedules,
	socket,
	dashboard,
	product,
};
