import { APIServerError } from '@/lib/Errors';
import $http from '../../plugins/axios';

export async function getCsrfCookie () {
	const response = await $http.get('/sanctum/csrf-cookie');
	if (response.status !== 204) {
		throw new APIServerError('Unable to get security token');
	}
}

export async function getUserInfo () {
	const response = await $http.get('/api/me?channels=1');
	if (response.status !== 200) {
		throw new APIServerError('Unable to load User info');
	}
	return response.data.data;
}

export async function postLogout () {
	const response = await $http.post('/auth/logout');
	// TODO Does this need error handling, or can we assume it always works?
	return response.data;
}

export async function authorizeBroadcastChannel ({ channelName, socketId }, callback) {
	try {
		const response = await $http.post('/broadcasting/auth', {
			channel_name: channelName,
			socket_id: socketId,
		});
		callback(null, response.data);
	} catch (e) {
		callback(e, null);
	}
}
