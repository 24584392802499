import Repository from '@/repositories/RepositoryFactory';

const Task = Repository.get('client', 'task');
const TaskV1 = Repository.get('client', 'taskV1');

export default {
	namespaced: true,
	state: {
		tasks: [],
	},
	getters: {

	},
	mutations: {
		ORGANIZATION_TASKS (state, payload) {
			state.tasks = payload.data;
		},
	},
	actions: {
		async getTasksByOrganization ({ commit }, id) {
			commit('ORGANIZATION_TASKS', await Task.where('organization_id', id).get());
		},
		async store ({ commit }, object) {
			const res = await new Task(object);
			res.save();
			return res;
		},
		async update ({ commit }, object) {
			const model = await Task.find(object.id);
			model.name = object.name;
			model.initial_duration = object.initial_duration;
			model.patch();
		},
		async updateV1 ({ commit }, object) {
			const res = await TaskV1.update(object);
			return res;
		},
		async updateTaskV1 ({ commit }, object) {
			const res = await TaskV1.updateTask(object);
			return res;
		},
		async updateV1Checklist ({ commit }, object) {
			const res = await TaskV1.updateChecklist(object);
			return res;
		},
	},
};
