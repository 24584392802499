import $http from '../../../plugins/axios';
import { ScheduleError } from '@/lib/Errors';

export default {
	async update (body) {
		const response = await $http.patch(`/api/schedules/${body.id}?type=simple`, body);
		if (response.status === 200) {
			return response.data;
		} else {
			if (response.response.status === 422) {
				return response.response.data;
			}
			throw new ScheduleError('Error getting branch structure');
		}
	},
	async getScheduleRegion (id) {
		const response = await $http.get(`/api/organizations/${id}/defaultSchedule?include=defaultSchedule.*&show_holidays=1`);
		if (response.status === 200) {
			return response.data.data;
		} else {
			if (response.response.status === 422) {
				return response.response.data;
			}
			throw new ScheduleError('Error getting branch structure');
		}
	},
	async getScheduleLocation (id) {
		const response = await $http.get(`/api/regions/${id}/defaultSchedule?show_holidays=1&schedule_type=simple&include=defaultSchedule.*`);
		if (response.status === 200) {
			return response.data.data;
		} else {
			if (response.response.status === 422) {
				return response.response.data;
			}
			throw new ScheduleError('Error getting branch structure');
		}
	},
};
