import Survey from '@/lib/models/Client/Survey';
import UserSurveyForNotify from '@/lib/models/Client/UserSurveyForNotify';
import UserExternalForSurvey from '@/lib/models/Client/UserExternalForSurvey';
import { SurveyError } from '@/lib/Errors';
import $http from '../../../plugins/axios';

export default {
	async get () {
		const body = {
			case: 'GET-ALL-SURVEYS',
		};

		const response = await $http.post('/api/client/managesurveys', body);

		if (response.data?.success) {
			const data = response.data.body.temp_surveyobject || [];
			const surveys = data.map(s => {
				const survey = new Survey(s);
				return survey;
			});
			return surveys;
		} else {
			throw new SurveyError('Error retrieving Surveys');
		}
	},

	async getSurveyById (id) {
		const body = {
			case: 'GET-SURVEY-BY-ID',
			surveyId: id,
		};

		const response = await $http.post('/api/client/managesurveys', body);

		if (response.data?.success) {
			const data = response.data.body.temp_surveyobject;
			const survey = new Survey(data);
			return survey;
		} else {
			throw new SurveyError('Error retrieving Survey');
		}
	},

	async getBranchSurvey (id) {
		const body = {
			case: 'GET-SURVEY',
			branchId: id,
		};

		const response = await $http.post('/api/client/managesurveys', body);

		if (response.data?.success) {
			const data = response.data.body.temp_surveyobject;
			const survey = new Survey(data);
			return survey;
		} else {
			throw new SurveyError('Error retrieving Survey');
		}
	},
	async update (object) {
		const response = await $http.patch(`/api/surveys/${object.id}`, object);
		if (response.status === 200) {
			return response.data;
		} else {
			if (response.response.status === 422) {
				return response.response.data;
			}
			throw new SurveyError('Error updating Survey');
		}
	},
	async getSurveyExternalUser (object) {
		// const body = {
		// 	case: 'GET-SURVEY',
		// 	branchId: id,
		// };

		const response = await $http.post('/api/client/managesurveysexternaluser', object);

		if (response.data?.success) {
			const data = response.data.body.temp_surveyobject;
			const survey = new Survey(data);
			return survey;
		} else {
			throw new SurveyError('Error retrieving Survey');
		}
	},

	async storeV1 (body) {
		const response = await $http.post(`/api/organizations/${body.organization_id}/surveys`, body);
		if (response.status === 201) {
			return response;
		} else {
			if (response.response.status === 422) {
				return response;
			}
			throw new SurveyError('Error storing Servey');
		}
	},
	async updateName (object) {
		object.case = 'UPDATE-SURVEY-NAME';

		const response = await $http.post('/api/client/managesurveys', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new SurveyError('Error updating Survey');
		}
	},

	async assign (object) {
		object.case = 'ASSIGN-SURVEY';

		const response = await $http.post('/api/client/managesurveys', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new SurveyError('Error assigning Survey');
		}
	},

	async deassign (id) {
		const body = {
			case: 'DE-ASSIGN-SURVEY',
			branchId: id,
		};

		const response = await $http.post('/api/client/managesurveys', body);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new SurveyError('Error unassigning Survey');
		}
	},

	async getSurveyUsers (id) {
		const body = {
			case: 'GET-USERS-TO-NOTIFY',
			branchId: id,
		};

		const response = await $http.post('/api/client/managesurveys', body);

		if (response.data?.success) {
			const data = response.data.body.temp_surveyobject || [];
			const users = data.map(u => {
				const user = new UserSurveyForNotify(u);
				return user;
			});
			return users;
		} else {
			throw new SurveyError('Error getting survey user list');
		}
	},

	// async getUsersToSelectForSurvey (id) {
	// 	const body = {
	// 		case: 'GET-USERS-TO-SELECT-FOR-SURVEY',
	// 		branchId: id,
	// 	};
	//
	// 	const response = await $http.post('/api/client/managesurveys', body);
	//
	// 	if (response.data?.success) {
	// 		const data = response.data.body.temp_surveyobject || [];
	// 		const users = data.map(u => {
	// 			const user = new UserSurveyForNotify(u);
	// 			return user;
	// 		});
	// 		return users;
	// 	} else {
	// 		throw new SurveyError('Error retreiving users for survey');
	// 	}
	// },
	async addExternalUsers (users) {
		const body = {
			case: 'ADD-EXTERNAL-USERS',
			users: users,
		};

		const response = await $http.post('/api/client/manageexternalusers', body);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new SurveyError('Error adding external users');
		}
	},

	async updateListOfExtUsersForSurvey (object) {
		object.case = 'DELETE-EXTERNAL-USERS';

		const response = await $http.post('/api/client/manageexternalusers', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new SurveyError('Error updating list of external users');
		}
	},

	async getExternalUsersByBranch (id) {
		const body = {
			case: 'GET-EXTERNAL-USERS-BY-BRANCH',
			branchId: id,
		};

		const response = await $http.post('/api/client/manageexternalusers', body);

		if (response.data?.success) {
			const data = response.data.body.externalusersobject || [];
			if (data) {
				const users = data.map(u => {
					const user = new UserExternalForSurvey(u);
					return user;
				});
				return users;
			} else {
				return [];
			}
		} else {
			throw new SurveyError('Error retreiving external users for survey');
		}
	},
	async updateUsersToNotifyForSurvey (object) {
		const response = await $http.patch(`/api/surveys/updateNotifyUsers/${object.id}`, object);
		if (response.status === 204) {
			return response;
		} else {
			throw new SurveyError('Error updating list of survey notification users');
		}
	},
	async updateSurveySchedule (object) {
		const response = await $http.patch(`/api/surveys/${object.id}`, object);
		if (response.status === 200) {
			return response.data;
		} else {
			throw new SurveyError('Error updating survey schedule');
		}
	},
	// async updateSurveySchedule (object) {
	// 	const response = await $http.patch(`/api/surveys/updateSurveySchedule/${object.id}`, object);
	// 	if (response.status === 200) {
	// 		return response.data;
	// 	} else {
	// 		throw new SurveyError('Error updating survey schedule');
	// 	}
	// },
	async updateUsersToTakeSurvey (object) {
		const response = await $http.patch(`/api/surveys/updateUsersToTakeSurvey/${object.id}`, object);
		if (response.status === 200) {
			return response;
		} else {
			throw new SurveyError('Error updating list of survey users');
		}
	},
	async assignSurveyToLocation (body) {
		const response = await $http.post(`/api/surveys/assignSurvey/${body.surveyId}`, body);
		if (response.status === 200) {
			return response.data;
		} else {
			throw new SurveyError('Error updating list of survey notification users');
		}
	},
	async getUsersToSelectForSurvey (body) {
		const response = await $http.get(`/api/surveys/${body.surveyId}/${body.locationId}`);
		if (response.status === 200) {
			return response.data;
		} else {
			throw new Error('Error fetching users');
		}
	},
	async getUsersToSelectForNotify (body) {
		const response = await $http.get(`api/surveysNotify/${body.surveyId}/${body.locationId}`);
		if (response.status === 200) {
			return response.data;
		} else {
			throw new Error('Error fetching users');
		}
	},
	async deAssignSurveyFromLocation (object) {
		const response = await $http.post(`/api/surveys/deAssignSurvey/${object.id}/location/${object.location_id}`);
		if (response.status === 204) {
			return response;
		} else {
			throw new SurveyError('Error assigning survey from location');
		}
	},
};
