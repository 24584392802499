import { DateTime, Duration } from 'luxon';

export const DATETIME_SHORT_WITH_SECONDS_TZ = Object.assign(DateTime.DATETIME_SHORT_WITH_SECONDS, { timeZoneName: 'short' });

const TIMESTAMP = Object.assign(DateTime.DATETIME_FULL_WITH_SECONDS, { month: 'short' });
const TIMESTAMP_MED = Object.assign(DateTime.DATE_FULL, { month: 'short' });

function getFormatFromString (format) {
	// NOTE: These are DIFFERENT from the ones in `formattedDateTime` because
	// several of those do not actually describe what they really output.
	switch (format) {
	case 'timestamp_med':
		return Object.assign(DateTime.DATETIME_MED_WITH_SECONDS, { timeZoneName: 'short' });
	case 'date_med':
		return DateTime.DATE_MED;
	case 'time':
		return DateTime.TIME_WITH_SHORT_OFFSET;
	case 'date':
		return DateTime.DATE_SHORT;
	case 'datetime_short':
		return DateTime.DATETIME_SHORT;
	case 'datetime_short_with_seconds':
		return DateTime.DATETIME_SHORT_WITH_SECONDS;
	case 'datetime_short_with_seconds_timezone':
		return Object.assign(DateTime.DATETIME_SHORT_WITH_SECONDS, { timeZoneName: 'short' });
	case 'time_24':
		return DateTime.TIME_24_WITH_SHORT_OFFSET;
	default:
		return format;
	}
}

/**
 * @deprecated Use the `dateFormat` filter instead
 */
export function formattedDateTime (timestamp, type, tz = null) {
	let format;

	if (type === 'timestamp') {
		format = TIMESTAMP;
	} else if (type === 'time') {
		format = DateTime.TIME_WITH_SHORT_OFFSET;
	} else if (type === 'date') {
		format = DateTime.DATE_SHORT;
	} else if (type === 'timestamp_med') {
		format = TIMESTAMP_MED;
	} else if (type === 'date_med') {
		format = DateTime.DATE_FULL;
	} else if (type === 'test') {
		format = DateTime.DATETIME_MED_WITH_SECONDS;
	}

	if (tz) {
		return DateTime.fromISO(timestamp, { zone: 'utc' }).setZone(tz).toLocaleString(format);
	} else {
		return DateTime.fromISO(timestamp, { zone: 'utc' }).toLocal().toLocaleString(format);
	}
}

/**
 * @deprecated Convert to ISO 8601 dates and then use the `dateFormat` filter instead
 */
export function formattedDateTimeFromSQL (timestamp, type, tz = null) {
	let format;

	if (type === 'timestamp') {
		format = TIMESTAMP;
	} else if (type === 'time') {
		format = DateTime.TIME_WITH_SHORT_OFFSET;
	} else if (type === 'date') {
		format = DateTime.DATE_SHORT;
	} else if (type === 'datetime_short_with_seconds_timezone') {
		format = Object.assign(DateTime.DATETIME_SHORT_WITH_SECONDS, { timeZoneName: 'short' });
	}

	if (tz) {
		return DateTime.fromSQL(timestamp, { zone: 'utc' }).setZone(tz).toLocaleString(format);
	} else {
		return DateTime.fromSQL(timestamp, { zone: 'utc' }).toLocal().toLocaleString(format);
	}
}

export function formatDuration (milliseconds) {
	return Duration.fromISOTime(milliseconds).as('minutes');
}

/**
 * @deprecated Use the `dateFormat` filter instead
 */
export function getNow () {
	return DateTime.now().toLocaleString(TIMESTAMP);
}

export function getNowSQL () {
	return DateTime.now().toSQL();
}

/** @deprecated Use luxon native `duration.toFormat('hh:mm:ss')` directly */
export function secondsToTimer (seconds) {
	const a = Math.abs(seconds);
	let time;
	if (seconds < 0) {
		time = Duration.fromMillis(a * 1000);
	} else {
		time = Duration.fromMillis(seconds * 1000);
	}

	return time.toFormat('hh:mm:ss');
}

export function addTime (timestamp, object) {
	return DateTime.fromISO(timestamp).plus(object);
}

/** @deprecated use luxon directly */
export function differenceTimeStamps (start, end) {
	if (start instanceof Date) {
		start = DateTime.fromJSDate(start);
	} else if (typeof start === 'string' || start instanceof String) {
		start = DateTime.fromISO(start);
	}

	if (end instanceof Date) {
		end = DateTime.fromJSDate(end);
	} else if (typeof end === 'string' || end instanceof String) {
		end = DateTime.fromISO(end);
	}

	return end.diff(start).as('seconds');
}

/** @deprecated use luxon directly */
export function formatterFilter (timestamp, format, timezone, defaultIfNull = '') {
	if (!timestamp) {
		return defaultIfNull;
	}

	format = getFormatFromString(format);

	if (DateTime.isDateTime(timestamp)) {
		return timestamp.toLocaleString(format);
	}

	if (timestamp instanceof Date) {
		if (timezone) {
			return DateTime.fromJSDate(timestamp, { zone: 'utc' }).setZone(timezone).toLocaleString(format);
		}
		return DateTime.fromJSDate(timestamp).toLocaleString(format);
	}

	if (typeof timestamp === 'string' || timestamp instanceof String) {
		if (timezone) {
			return DateTime.fromISO(timestamp, { zone: 'utc' }).setZone(timezone).toLocaleString(format);
		}
		return DateTime.fromISO(timestamp).toLocaleString(format);
	}

	if (Number.isFinite(timestamp)) {
		// TODO: Is Milliseconds better?
		return DateTime.fromSeconds(timestamp).toLocaleString(format);
	}

	return DateTime.invalid('invalid input').toLocaleString(format);
}
