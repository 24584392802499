export const SAFE_SECURITY = {
	primary: '#EF6C00',
	secondary: '#F3F4F6',
	accent: '#8c9eff',
	error: '#b71c1c',
	info: '#00CAE3',
	success: '#76FF03',
	warning: '#FF9800',
	open: '#76FF03',
	close: '#9E9E9E',
	service: '#FF9800',
	alarm: '#F44336',
};
