import Repository from '@/repositories/RepositoryFactory';

const SecurityPhraseRepository = Repository.get('client', 'securityPhrase');

export default {
	namespaced: true,
	state: {
		securityPhrases: [],
		securityPhrase: null,
		securityPhrasesForUpdate: [],
	},
	getters: {

	},
	mutations: {
		LOAD_SECURITY_PHRASES: (state, payload) => {
			state.securityPhrases = payload;
		},
		LOAD_SECURITY_PHRASE: (state, payload) => {
			state.securityPhrase = payload;
		},
		LOAD_SECURITY_PHRASE_FOR_UPDATE: (state, payload) => {
			const noDuressPhrases = payload.filter(c => { return c.IsDuress === false; });
			state.securityPhrasesForUpdate = noDuressPhrases;
		},
	},
	actions: {
		async getAllSecurityPhrases ({ commit }) {
			commit('LOAD_SECURITY_PHRASES', await SecurityPhraseRepository.getAllSecurityPhrases());
		},

		// async getSecurityPhrase ({ commit }, object) {
		// 	commit('LOAD_SECURITY_PHRASE', await SecurityPhraseRepository.getSecurityPhrase(object));
		// },
		// async getSecurityPhrasesForUpdate ({ commit }, object) {
		// 	commit('LOAD_SECURITY_PHRASE_FOR_UPDATE', await SecurityPhraseRepository.getSecurityPhraseForUpdate(object));
		// },

		async storeSecurityPhrase ({ commit }, object) {
			const response = await SecurityPhraseRepository.storeSecurityPhrase(object);
			return response;
		},

		async updateSecurityPhrase ({ commit }, object) {
			const response = await SecurityPhraseRepository.update(object);
			return response;
		},
	},
};
