// import DailyReport from '@/lib/models/Client/DailyReport';
import ActivityReport from '@/lib/models/Client/ActivityReport';
import ActivityReportDetails from '@/lib/models/Client/ActivityReportDetails';
// import CheckInOutReport from '@/lib/models/Client/CheckInOutReport';
// import SurveyReport from '@/lib/models/Client/SurveyReport';
import SummaryReport from '@/lib/models/Client/SummaryReport';
import Timer from '@/lib/models/Client/Timer';
// import SurveyReportDetails from '@/lib/models/Client/SurveyReportDetails';
import { ReportError } from '@/lib/Errors';
import $http from '../../../plugins/axios';

export default {
	async getDailyReports (object) {
		object.case = 'GET-ALL-BRANCH-REPORTING';
		const response = await $http.get(`/api/reports/daily?start=${object.start}&end=${object.end}&organization_id=${object.org_id}`, object);
		if (response.status === 200) {
			return response.data;
		} else {
			throw new ReportError('Error retrieving Daily Reports');
		}
	},
	async  getDailyReportsForSecuity (object) {
		object.case = 'GET-ALL-BRANCH-REPORTING';
		const response = await $http.get(`/api/reports/daily?start=${object.start}&end=${object.end}`, object);
		if (response.status === 200) {
			return response.data;
		} else {
			throw new ReportError('Error retrieving Daily Reports');
		}
	},
	async getLocationAndOrganization () {
		const response = await $http.get('/api/locations?include=region,organization');
		if (response.status === 200) {
			return response.data;
		} else {
			throw new ReportError('Error retrieving Daily Reports');
		}
	},
	async activitiesCount (object) {
		const response = await $http.get(`/api/reports/activitiesCount?start=${object.start}&end=${object.end}&organization_id=${object.org}`, object);
		if (response.status === 200) {
			const data = response.data;
			return data;
		} else {
			throw new ReportError('Error retrieving Activity Reports');
		}
	},
	async getActivityReports (object) {
		const response = await $http.get(`/api/reports/activities?start=${object.start}&end=${object.end}&organization_id=${object.organization_id}${object.user_id ? '&user_id=' + object.user_id : ''}`);
		if (response.status === 200) {
			const data = response.data;
			// const data2 = response.data.body.temp_ens_notificationobject || [];
			// if (data2.length > 0) {
			// 	Array.prototype.push.apply(data, data2);
			// }
			// const reports = data.map(r => {
			// 	const report = new ActivityReport(r);
			// 	return report;
			// });
			return data;
		} else {
			throw new ReportError('Error retrieving Activity Reports');
		}
	},
	async getActivityReportsForSecurity (object) {
		const response = await $http.get(`/api/reports/activities?start=${object.start}&end=${object.end}${object.user_id ? '&user_id=' + object.user_id : ''}`);
		if (response.status === 200) {
			const data = response.data;
			// const data2 = response.data.body.temp_ens_notificationobject || [];
			// if (data2.length > 0) {
			// 	Array.prototype.push.apply(data, data2);
			// }
			// const reports = data.map(r => {
			// 	const report = new ActivityReport(r);
			// 	return report;
			// });
			return data;
		} else {
			throw new ReportError('Error retrieving Activity Reports');
		}
	},
	async getActivityReportNotifications (body) {
		const response = await $http.patch(`/api/users/${body.id}/updateNotificationSettings`, body);
		if (response.status === 204) {
			return response;
		} else {
			throw new ReportError('Error retrieving Activity Report Details');
		}
	},
	async getActivityReportDetails (id) {
		const body = {
			taskid: id,
		};

		const response = await $http.post('/api/client/getnotifications', body);

		if (response.data?.success) {
			const data = response.data.body.notificationobject;
			const reports = new ActivityReportDetails(data);
			return reports;
		} else {
			throw new ReportError('Error retrieving Activity Report Details');
		}
	},

	async getCheckInOutReports (filter) {
		const body = {
			param_date_filter: filter.range,
			param_date1: filter.start,
			param_date2: filter.end,
			param_date3: filter.organization_id,
		};

		const response = await $http.get(`/api/reports/punches?start=${filter.start}&end=${filter.end}&organization_id=${filter.organization_id}`, body);

		if (response.status === 200) {
			return response.data;
		} else {
			throw new ReportError('Error retrieving Check In/Out Reports');
		}
	},
	async getCheckInOutReportsForSecurity (filter) {
		const body = {
			param_date_filter: filter.range,
			param_date1: filter.start,
			param_date2: filter.end,
			param_date3: filter.organization_id,
		};

		const response = await $http.get(`/api/reports/punches?start=${filter.start}&end=${filter.end}`, body);

		if (response.status === 200) {
			return response.data;
		} else {
			throw new ReportError('Error retrieving Check In/Out Reports');
		}
	},
	async getSurveyReports (req) {
		const response = await $http.get(`/api/reports/survey?start=${req.fromDate}&end=${req.toDate}&organization_id=${req.org_id}`);
		if (response.status === 200) {
			return response.data;
		} else {
			throw new ReportError('Error retrieving Survey Reports');
		}
	},

	async getBranchActivities (id) {
		const body = {
			case: 'GET-ACTIVITIES-BY-BRANCH-ID',
			branchOfficeId: `${id}`,
		};

		const response = await $http.post('/api/client/managebranchoffices', body);

		if (response.data?.success) {
			const data = response.data.body.organizationobject || [];
			const activities = data.map(a => {
				const activity = new ActivityReport(a);
				return activity;
			});
			return activities;
		} else {
			throw new ReportError('Error retrieving Branch Activities');
		}
	},

	async getSurveyReportDetails (id) {
		const response = await $http.get(`/api/reports/surveyDetails/${id}`);

		if (response.status === 200) {
			return response.data;
		} else {
			throw new ReportError('Error retrieving Survey Report Details');
		}
	},

	async getSummaryReports (object) {
		const response = await $http.post('/api/client/managesummaryreports', object);

		if (response.data?.status !== 200) {
			throw new ReportError('Error retrieving Summary Reports');
		} else {
			if (response.data?.success) {
				const data = response.data.body.resultobject.APPObject;
				const report = new SummaryReport(data);
				return report;
			} else return response;
		}
	},

	async getTimerReports (object) {
		const response = await $http.get(`/api/reports/timers?start=${object.start}&end=${object.end}&organization_id=${object.organization_id}`);
		if (response.status === 200) {
			return response.data;
		} else {
			throw new ReportError('Error retrieving Timer Reports');
		}
	},
	async getTimerReportsForSecurity (object) {
		const response = await $http.get(`/api/reports/timers?start=${object.start}&end=${object.end}`);
		if (response.status === 200) {
			return response.data;
		} else {
			throw new ReportError('Error retrieving Timer Reports');
		}
	},
	async getTimerTrackings () {
		const body = {
			case: 'GET-NON-RETAIL-PINGS',
		};

		const response = await $http.post('/api/client/gettrackings', body);

		if (response.data?.success) {
			const data = response.data.body.resultobject || [];
			if (data) {
				const reports = data.map(r => {
					const report = new Timer(r);
					return report;
				});
				return reports;
			} else {
				return [];
			}
		} else {
			throw new ReportError('Error retrieving Timer Reports');
		}
	},
};
