import Repository from '@/repositories/RepositoryFactory';

const ReportRepository = Repository.get('client', 'report');

export default {
	namespaced: true,
	state: {
		dailyReports: [],
		getLocationAndOrganization: [],
		activityReports: {
			organizations: [],
			regions: [],
			locations: [],
			users: [],
			tasks: [],
			checklists: [],
			events: [],
		},
		activityReportDetails: {},
		surveyReports: [],
		surveyReportDetails: [],
		checkInOutReports: [],
		summaryReports: [],
		timerReports: [],
		activitiesCountReports: [],
	},
	getters: {

	},
	mutations: {
		LOAD_DAILY_REPORTS: (state, payload) => {
			state.dailyReports = payload;
		},
		LOAD_DAILY_REPORTS_ALL: (state, payload) => {
			state.getLocationAndOrganization = payload;
		},
		LOAD_ACTIVITIES_COUNT_REPORTS: (state, payload) => {
			state.activitiesCountReports = payload;
		},
		LOAD_ACTIVITY_REPORTS: (state, payload) => {
			state.activityReports = payload;
		},
		ADD_NOTIFICATION: (state, { organization, region, location, user, task, event }) => {
			if (!state.activityReports.organizations.some(it => it.id === organization.id)) {
				state.activityReports.organizations.push(organization);
			}
			if (!state.activityReports.regions.some(it => it.id === region.id)) {
				state.activityReports.regions.push(region);
			}
			if (!state.activityReports.locations.some(it => it.id === location.id)) {
				state.activityReports.locations.push(location);
			}
			if (!state.activityReports.users.some(it => it.id === user.id)) {
				state.activityReports.users.push(user);
			}
			if (!state.activityReports.tasks.some(it => it.id === task.id)) {
				state.activityReports.tasks.push(task);
			}
			state.activityReports.events.unshift(event);
		},
		LOAD_ACTIVITY_REPORT_DETAILS: (state, payload) => {
			state.activityReportDetails = payload;
		},
		LOAD_CHECKINOUT_REPORTS: (state, payload) => {
			state.checkInOutReports = payload;
		},
		LOAD_SURVEY_REPORTS: (state, payload) => {
			state.surveyReports = payload;
		},
		LOAD_SURVEY_REPORT_DETAILS: (state, payload) => {
			state.surveyReportDetails = payload;
		},
		LOAD_SUMMARY_REPORTS: (state, payload) => {
			state.summaryReports = payload;
		},
		LOAD_TIMER_REPORTS: (state, payload) => {
			state.timerReports = payload;
		},
	},
	actions: {
		async getDailyReportsFromRepo ({ commit }, object) {
			commit('LOAD_DAILY_REPORTS', await ReportRepository.getDailyReports(object));
		},
		async getDailyReportsFromRepoForSecurity ({ commit }, object) {
			commit('LOAD_DAILY_REPORTS', await ReportRepository.getDailyReportsForSecuity(object));
		},
		async activitiesCount ({ commit }, object) {
			commit('LOAD_ACTIVITIES_COUNT_REPORTS', await ReportRepository.activitiesCount(object));
		},
		async getLocationAndOrganization ({ commit }) {
			commit('LOAD_DAILY_REPORTS_ALL', await ReportRepository.getLocationAndOrganization());
		},

		async getActivityReportsFromRepo ({ commit }, object) {
			commit('LOAD_ACTIVITY_REPORTS', await ReportRepository.getActivityReports(object));
		},
		async getActivityReportsForSecurity ({ commit }, object) {
			commit('LOAD_ACTIVITY_REPORTS', await ReportRepository.getActivityReportsForSecurity(object));
		},
		async getActivityReportDetailsFromRepo ({ commit }, id) {
			commit('LOAD_ACTIVITY_REPORT_DETAILS', await ReportRepository.getActivityReportDetails(id));
		},
		async getActivityReportDetails ({ commit }, id) {
			const result = await ReportRepository.getActivityReportNotifications(id);
			commit('LOAD_ACTIVITY_REPORT_DETAILS', result);
			return result;
		},
		async getCheckInOutReportsFromRepo ({ commit }, filter) {
			commit('LOAD_CHECKINOUT_REPORTS', await ReportRepository.getCheckInOutReports(filter));
		},
		async getCheckInOutReportsForSecurity ({ commit }, filter) {
			commit('LOAD_CHECKINOUT_REPORTS', await ReportRepository.getCheckInOutReportsForSecurity(filter));
		},
		async getSurveyReportsFromRepo ({ commit }, object) {
			commit('LOAD_SURVEY_REPORTS', await ReportRepository.getSurveyReports(object));
		},

		async getSurveyReportDetails ({ commit }, id) {
			commit('LOAD_SURVEY_REPORT_DETAILS', await ReportRepository.getSurveyReportDetails(id));
		},
		async getSummaryReports ({ commit }, object) {
			commit('LOAD_SUMMARY_REPORTS', await ReportRepository.getSummaryReports(object));
		},
		async getTimerReportsFromRepo ({ commit }, object) {
			commit('LOAD_TIMER_REPORTS', await ReportRepository.getTimerReports(object));
		},
		async getTimerReportsForSecurity ({ commit }, object) {
			commit('LOAD_TIMER_REPORTS', await ReportRepository.getTimerReportsForSecurity(object));
		},
	},
};
