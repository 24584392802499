import { format, parse, subDays, startOfMonth, endOfMonth, startOfWeek, endOfWeek } from 'date-fns';
export const ISO_FORMAT = 'yyyy-MM-dd';
export const DEFAULT_DATE = format(new Date(), ISO_FORMAT);
export const DEFAULT_ACTION_LABELS = {
	apply: 'Apply',
	cancel: 'Cancel',
	reset: 'Reset',
};

export function formatDate (date, fmt) {
	return format(parse(date, ISO_FORMAT, new Date()), fmt);
}

export const PRESETS = [
	{
		label: 'Today',
		value: 'today',
		range: [
			format(new Date(), ISO_FORMAT),
			format(new Date(), ISO_FORMAT),
		],
	},
	{
		label: 'Yesterday',
		value: 'yesterday',
		range: [
			format(subDays(new Date(), 1), ISO_FORMAT),
			format(subDays(new Date(), 1), ISO_FORMAT),
		],
	},
	{
		label: 'Last 7 Days',
		value: 'last_seven_days',
		range: [
			format(subDays(new Date(), 6), ISO_FORMAT),
			format(new Date(), ISO_FORMAT),
		],
	},
	{
		label: 'This Week',
		value: 'this_week',
		range: [
			format(startOfWeek(new Date()), ISO_FORMAT),
			format(endOfWeek(new Date()), ISO_FORMAT),
		],
	},
	{
		label: 'Last Week',
		value: 'last_week',
		range: [
			format(startOfWeek(subDays(startOfWeek(new Date()), 1)), ISO_FORMAT),
			format(endOfWeek(subDays(startOfWeek(new Date()), 1)), ISO_FORMAT),
		],
	},
	{
		label: 'Last 30 Days',
		value: 'last_thirty_days',
		range: [
			format(subDays(new Date(), 30), ISO_FORMAT),
			format(new Date(), ISO_FORMAT),
		],
	},
	{
		label: 'This Month',
		value: 'this_month',
		range: [
			format(startOfMonth(new Date()), ISO_FORMAT),
			format(new Date(), ISO_FORMAT),
		],
	},
	{
		label: 'Last Month',
		value: 'last_month',
		range: [
			format(startOfMonth(subDays(startOfMonth(new Date()), 1)), ISO_FORMAT),
			format(endOfMonth(subDays(startOfMonth(new Date()), 1)), ISO_FORMAT),
		],
	},
];
