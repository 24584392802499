import Repository from '@/repositories/RepositoryFactory';
import OrganizationModel from '@/repositories/v2/Organization';

const Organization = Repository.get('client', 'organization');
const OrganizationV1 = Repository.get('client', 'organizationV1');
const Domain = Repository.get('client', 'domain');
const Schedule = Repository.get('client', 'schedule');

export default {
	namespaced: true,
	state: {
		organization: {},
		organizations: [],
		securityOffices: [],
		clients: [],
		location: [],
		organizationBasic: [],
		organizationAdvanced: [],
		organizationAdvancedUsers: [],
		loadingSecond: false,
		loadingFirst: false,
		loadingThird: false,
		// getFeatures: [],
	},
	getters: {

	},
	mutations: {
		LOAD_ORGANIZATIONS: (state, payload) => {
			state.organizations = payload.data;
		},
		LOAD_ORGANIZATION: (state, payload) => {
			state.organization = payload.data;
		},
		LOAD_ORGANIZATION_BASIC: (state, payload) => {
			state.organizationBasic = payload.data;
		},
		LOAD_ORGANIZATION_ADVANCED: (state, payload) => {
			state.organizationAdvanced = payload.data;
		},
		LOAD_SECURITY_OFFICES: (state, payload) => {
			state.securityOffices = payload.data;
		},
		LOAD_CLIENTS: (state, payload) => {
			state.clients = payload.data;
		},
		LOAD_ORGANIZATION_USERS: (state, payload) => {
			state.organizationAdvancedUsers = payload;
		},
		SET_LOADING_SECOND (state, loading) {
			state.loadingSecond = loading;
		},
		SET_LOADING_FIRST (state, loading) {
			state.loadingFirst = loading;
		},
		SET_LOADING_THIRD (state, loading) {
			state.loadingThird = loading;
		},
		// LOAD_FEATURES: (state, payload) => {
		// 	state.getFeatures = payload;
		// },
	},
	actions: {
		async get ({ commit }) {
			commit('LOAD_ORGANIZATIONS', await Organization.all());
		},
		async getAll ({ commit }, id) {
			commit('LOAD_ORGANIZATIONS', await Organization.include(['phones', 'addresses']).where('trashed', 'with').get());
		},
		async getOrganizationById ({ commit }, id) {
			commit('LOAD_ORGANIZATION', await Organization.include(['phones', 'addresses', 'domains', 'schedules.*', 'defaultSchedule.*']).find(id));
		},
		async getOrganizationDetails ({ commit }, id) {
			commit('SET_LOADING_FIRST', true);
			commit('LOAD_ORGANIZATION', await Organization.include(['domains', 'phones', 'addresses', 'regions.phones', 'users.assignments.role', 'users.phones', 'users.addresses', 'defaultSchedule.*', 'regions.phones', 'regions.locations', 'schedules.*']).params({ schedule_type: 'simple', show_holidays: 1 }).find(id));
			commit('SET_LOADING_FIRST', false);
		},
		getOrganizationDetailsMAIN ({ dispatch }, id) {
			dispatch('getOrganizationDetailsFIRST', id);
			dispatch('getOrganizationDetailsSECOND', id);
		},
		async getOrganizationDetailsBasic ({ commit }, id) {
			commit('LOAD_ORGANIZATION_BASIC', await Organization.include(['domains', 'phones', 'addresses', 'defaultSchedule.*', 'schedules.*']).params({ schedule_type: 'simple', show_holidays: 1 }).find(id));
		},
		async getOrganizationDetailsAdvanced ({ commit }, id) {
			commit('SET_LOADING_SECOND', true);
			const data = await Organization.include(['regions.phones', 'regions.phones', 'regions.locations']).params({ schedule_type: 'simple', show_holidays: 1 }).find(id);
			commit('LOAD_ORGANIZATION_ADVANCED', data);
			commit('SET_LOADING_SECOND', false);
		},
		async getOrganizationDetailsUsers ({ commit }, id) {
			commit('SET_LOADING_THIRD', true);
			commit('LOAD_ORGANIZATION_USERS', await (new OrganizationModel({ id })).users().$get());
			commit('SET_LOADING_THIRD', false);
		},
		async getOrganizationDetailsV2 ({ commit }, id) {
			return Organization.include(['domains', 'phones', 'addresses', 'regions.phones', 'users.assignments.role', 'users.phones', 'users.addresses', 'defaultSchedule.*', 'regions.phones', 'regions.locations', 'schedules.*'])
				.params({ schedule_type: 'simple', show_holidays: 1 })
				.find(id)
				.then(response => {
					// Commit the mutation to update the state
					commit('LOAD_ORGANIZATION', response);

					// Return the response
					return response;
				});
		},
		async getSecurityOffices ({ commit }) {
			commit('LOAD_SECURITY_OFFICES', await Organization.where('is_security', '1').include(['phones', 'addresses']).get());
		},
		async getClients ({ commit }) {
			commit('LOAD_CLIENTS', await Organization.get());
		},
		async storeV1 ({ commit }, object) {
			const res = await OrganizationV1.store(object);
			return res;
		},
		async getType ({ commit }, object) {
			const res = await OrganizationV1.getType(object);
			return res;
		},
		// async getFeatures ({ commit }, object) {
		// 	commit('LOAD_FEATURES', await OrganizationV1.getFeatures(object));
		// },
		async storeV2 ({ commit }, object) {
			const res = await new Organization(object);
			res.save();
			return res;
		},
		async updateV1 ({ commit }, object) {
			const res = await OrganizationV1.update(object);
			return res;
		},
		async setActiveClient ({ commit }, object) {
			const res = await OrganizationV1.setActiveClient(object);
			return res;
		},
		async updateScheduleV1 ({ commit }, object) {
			const res = await OrganizationV1.updateScheduleV1(object);
			return res;
		},
		async deleteV1 ({ commit }, object) {
			await OrganizationV1.delete(object);
		},
		async update ({ commit }, object) {
			const model = await Organization.find(object.id);
			model.name = object.name;
			model.address = object.addresses;
			model.phone = object.phones;
			model.patch();
		},
		async storeDomainsV1 ({ commit }, object) {
			const res = await OrganizationV1.storeDomainsV1(object);
			return res;
		},
		async deleteDomainsV1 ({ commit }, id) {
			const res = await OrganizationV1.deleteDomainsV1(id);
			return res;
		},
		async storeDomains ({ commit }, object) {
			const organization = await Organization.include(['phones', 'addresses', 'domains']).find(object.organizationId);
			const domain = new Domain(object).for(organization);
			const res = await domain.save();
			return res;
		},
		async storeScheduleV1 ({ commit }, object) {
			const res = await OrganizationV1.storeScheduleV1(object);
			return res;
		},
		async storeSchedule ({ commit }, object) {
			const organization = await Organization.include(['schedules']).find(object.organization_id);
			const schedule = new Schedule(object).for(organization);
			const res = await schedule.save();
			return res;
		},
		async getSchedule ({ commit }, id) {
			const res = await Organization.custom(`organizations/${id}/schedules?include=*`).get();
			return res?.data;
		},
	},
};
