<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="500"
      style="z-index: 9999"
    >
      <v-card class="card-title">
        <v-card-title>
          <h4>{{ $t('profile') }}</h4>
        </v-card-title>
        <template>
          <Alert
			v-if="alert"
			:type="alertType"
			dense
			text
			dismissible
			v-model="visibility"
			>{{ alertMessage }}</Alert
          >
            <v-card flat>
              <v-card-text>
                <v-container>
                  <v-row>
                    <!-- <v-col class="text-center" cols="5" fluid>
                      <AvatarSelector
                        v-model="file"
                        :formats="formats"
                        :size="sizeKB"
                      />
                    </v-col> -->
                    <v-col cols="12" fluid>
                      <v-row>
                        <v-col md="6">
                          <validation-provider
                            :name="$t('name')"
                            rules="required"
                          >
                            <v-text-field
                              items="profileName"
                              v-model="profileName"
                              prepend-inner-icon="mdi-account"
                              item-text="name"
                              item-value="id"
                              :label="$t('first-name')"
                              outlined
                              dense
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="6">
                          <validation-provider
                            :name="$t('name')"
                            rules="required"
                          >
                            <v-text-field
                              items="profileSurname"
                              prepend-inner-icon="mdi-account"
                              v-model="profileSurname"
                              item-text="name"
                              item-value="id"
                              :label="$t('surname')"
                              outlined
                              dense
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-divider></v-divider>
                        <v-col cols="12" sm="12">
                          <v-banner
                            dense
                            :label="$t('role')"
                            single-line
                            icon="mdi-account-supervisor-circle"
                            >{{userRole}}</v-banner
                          >
                        </v-col>
                        <v-divider></v-divider>
                        <v-col cols="12" sm="12">
                          <v-banner
                            filter
                            :label="$t('email-example')"
                            prepend-inner-icon="mdi-email-open"
                            single-line
                            icon="mdi-email-open"
                            >{{emailProfile}}</v-banner
                          >
                        </v-col>
                        <v-col cols="12" sm="12">
                          <v-banner
                            mdi-filter
                            filter
                            label="+389 xx xxx xxx"
                            single-line
                            icon="mdi-cellphone"
                            >{{phoneNumber}}</v-banner
                          >
                        </v-col>
                      </v-row>
                      <v-col cols="9">
                        <v-btn
                          depressed
                          color=""
                          elevation="4"
                          raised
                          @click="hidden = !hidden"
                        >
                          {{ $t("changepassword") }}
                          <v-icon v-if="!hidden" color="primary"
                            >mdi-chevron-up</v-icon
                          >
                          <v-icon v-else color="primary"
                            >mdi-chevron-down</v-icon
                          >
                        </v-btn>
                        <validation-provider
                          :name="$t('oldpassword')"
                          rules="required"
                        >
                          <v-text-field
                            v-if="!hidden"
                            prepend-inner-icon="mdi-key-variant"
                            class="mt-5"
                            v-model="oldPassword"
                            :label="$t('oldpassword')"
							:type="showPassword ? 'text' : 'password'"
							:append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
							@click:append="showPassword = !showPassword"
                            outlined
                            dense
                          >
                          </v-text-field>
                        </validation-provider>
                        <validation-provider
                          :name="$t('newpassword')"
                          rules="required"
                        >
                          <v-text-field
                            v-if="!hidden"
                            v-model="newPassword"
                            :label="$t('newpassword')"
                            prepend-inner-icon="mdi-key-variant"
							:type="showPassword1 ? 'text' : 'password'"
							:append-icon="showPassword1 ? 'mdi-eye-off' : 'mdi-eye'"
							@click:append="showPassword1 = !showPassword1"
                            outlined
                            dense
                          ></v-text-field>
                        </validation-provider>
                        <validation-provider
                          v-if="!hidden"
                          :name="$t('cnfpassword')"
                          rules="required"
                        >
                          <v-text-field
                            v-model="confirmPassword"
                            :label="$t('cnfpassword')"
							:type="showPassword2 ? 'text' : 'password'"
							:append-icon="showPassword2 ? 'mdi-eye-off' : 'mdi-eye'"
							@click:append="showPassword2 = !showPassword2"
                            prepend-inner-icon="mdi-key-variant"
                            outlined
                            dense
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
        </template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">
            {{ $t("close") }}
          </v-btn>
          <v-btn color="primary" @click="save">
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
import Alert from '@/components/base/MaterialAlert.vue';
import countries from '@/lib/Countries.js';
// import AvatarSelector from './AvatarSelector';

export default {
	name: 'Profile',
	components: {
		// AvatarSelector,
		Alert,
	},
	props: ['dialog'],
	data: () => ({
		file: null,
		formats: ['image/jpg', 'image/jpeg', 'image/png'],
		sizeKB: 700,
		showPassword: false,
		showPassword1: false,
		showPassword2: false,
		selectableData: {
			countries: countries,
		},
		doNotDisturb: false,
		tempProfileName: null,
		tempProfileSurname: null,
		filters: [
			{ text: 'Today', value: 'today' },
			{ text: 'Yesterday', value: 'yesterday' },
			{ text: 'This Week', value: 'this_week' },
			{ text: 'Last Week', value: 'last_week' },
			{ text: 'This Month', value: 'this_month' },
			{ text: 'Last Month', value: 'last_month' },
		],
		visibility: true,
		hidden: true,
		oldPassword: null,
		newPassword: null,
		confirmPassword: null,
		profileName: null,
		profileSurname: null,
		emailProfile: null,
		userRole: null,
		phoneNumber: null,
		alertMessage: '',
		alertType: '',
		alert: false,
		errorMessage: '',
		tab: null,
	}),
	computed: {
		...mapState({
			userObject: state => state.authentication.userInfo,
			authentication: state => state.authentication,
		}),
		countriesList: {
			get () {
				return this.selectableData.countries.map(c => {
					return c.name;
				});
			},
		},
	},
	watch: {
		dialog (val) {
			if (val === true) {
				// this.doNotDisturb = this.userObject.UserSettings.NotificationDoNotDisturb;
				if (this.profileName === null) {
					this.profileName = this.userObject.given_name;
					this.tempProfileName = this.profileName;
				}
				if (this.profileSurname === null) {
					this.profileSurname = this.userObject.surname;
					this.tempProfileSurname = this.profileSurname;
				}
				this.emailProfile = this.userObject.email;
				this.phoneNumber = this.userObject?.phones[0]?.number;
				this.userRole = this.userObject?.assignments[0]?.roles?.name;
			}
		},
		visibility (val) {
			if (!val) {
				this.alert = false;
				this.visibility = true;
			}
		},
	},
	methods: {
		close () {
			this.$emit('user-profile-closed');
			this.hidden = true;
			this.alert = false;
			this.alertType = '';
			this.alertMessage = '';
		},
		save () {
			if (this.profileName && this.profileSurname) {
				const updateUser = {
					given_name: this.profileName,
					surname: this.profileSurname,
					id: this.userObject.id,
				};
				this.$store.dispatch('users/updateV1', updateUser).then(res => {
					this.$store.dispatch('authentication/loadUserData');
					if (res.message !== undefined) {
						this.$store.commit('alerts/add', {
							id: 'createClient',
							type: 'error',
							message: res.message,
						});
					} else {
						this.close();
					}
				});
			}
			if (this.oldPassword && this.newPassword && this.confirmPassword) {
				const updatePass = {
					old_password: this.oldPassword,
					new_password: this.newPassword,
					confirm_password: this.confirmPassword,
					user_id: this.userObject.id,
				};
				this.$store.dispatch('users/updatePassword', updatePass).then(res => {
					if (res.message !== undefined) {
						this.$store.commit('alerts/add', {
							id: 'createClient',
							type: 'error',
							message: res.message,
						});
					} else {
						this.close();
					}
				});
			}
		},
	},
};
</script>
<style scoped>
	.tableTabs {
		width: 110px;
	}
	.v-card__title {
        background: #F3F4F6;
        color: #000;
        height: 40px;
        padding: 5px 20px;
    }
	.v-dialog > .v-card > .v-card__title {
        background: #F3F4F6;
        color: #000;
        height: 40px;
        padding: 5px 20px;
    }
</style>
