<template>
	<v-dialog
		v-model="dialog"
		persistent
		scrollable
		min-width="1800px"
		width="1800px"
		max-width="1800px"
		style="z-index: 9999"
	>
		<v-card>
			<v-card-title>
				<span>{{ $t('emergencyNotification') }}</span>
				<v-spacer></v-spacer>
				<v-btn
					icon
					small
					@click="close"
				>
					<v-icon color="primary">mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text class="grid-container mt-3">
				<v-card
					class="location-select"
					:loading="loadingTreeView"
					outlined
				>
					<v-card-title>
						{{ $t('sendToLocations') }}
						<v-spacer />
						<v-spacer />
						<v-text-field
							v-model="searchTreeView"
							append-icon="mdi-magnify"
							:label="$t('search')"
							single-line
							hide-details
							dense
						/>
					</v-card-title>
					<v-card-text>
						<v-treeview
							:search="searchTreeView"
							v-model="selectedLocations"
							:items="availableLocations"
							item-children="locations"
							item-key="uniqId"
							dense
							selectable
							selected-color="primary"
							close-all
							return-object
							style="overflow-y: auto; max-height: 380px; font-size: 1rem;"
						>
							<template v-slot:append="{ item }">
								<v-chip v-if="hasLocations(item)" x-small>{{item.locations.length}}</v-chip>
								<v-chip v-else-if="isRegion(item)" x-small>No locations</v-chip>
							</template>
						</v-treeview>
					</v-card-text>
				</v-card>
				<v-card
					class="user-select"
					:loading="loadingUserList"
					outlined
				>
					<v-card-title>
						<span v-if="isOpen" style="text-transform: capitalize" class="elevation-0" @click="toggleAll()"><v-icon color="primary">mdi-chevron-up</v-icon></span>
						<span v-if="!isOpen" style="text-transform: capitalize" class="elevation-0" @click="toggleAll()"><v-icon color="primary">mdi-chevron-down</v-icon></span>
						{{ $t('sendToUsers') }}
						<v-spacer />
						<v-spacer />
						<v-spacer />
						<v-text-field
							v-model="searchDataTable"
							append-icon="mdi-magnify"
							:label="$t('search')"
							single-line
							hide-details
							style="width: 5%;"
							dense
						></v-text-field>
					</v-card-title>
					<v-card-text>
						<v-data-table
							:search="searchDataTable"
							v-model="selectedUsers"
							:headers="userHeaders"
							:items="availableUsersList"
							item-key="id"
							show-select
							hide-default-footer
							:footer-props="footerProps"
							height="390"
							style="overflow-y: auto"
							group-by="roleId"
							sort-by="name"
							dense
						>
							<template v-slot:[`group.header`]="{ group, headers, toggle, isOpen, items }">
								<td :colspan="headers.length" style="background: #F3F4F6;">
									<v-btn @click="toggle" small icon :ref="group" :data-open="isOpen">
										<v-icon v-if="isOpen">mdi-chevron-up</v-icon>
										<v-icon v-else>mdi-chevron-down</v-icon>
									</v-btn>
									<span style="font-weight: bold;" v-if="true">{{ items[0].role }}</span>
									<v-chip pill x-small color="white" class="ma-2 text--black">
										{{ items.length }}
									</v-chip>
								</td>
							</template>
							<template v-slot:[`item.region`]="{ item }">
								<div v-for="(name, id) in item.regions" :key="id">{{ name }}</div>
							</template>
							<template v-slot:[`item.location`]="{ item }">
								<div v-for="(name, id) in item.locations" :key="id">{{ name }}</div>
							</template>
							<template v-slot:[`item.notificationStatus`]="{ item }">
								<v-tooltip bottom :color="notifIconColor(item.email, item.shouldEmail)">
									<template v-slot:activator="{ on, attrs }">
										<v-icon v-bind="attrs" v-on="on" class="mr-2" :color="notifIconColor(item.email, item.shouldEmail)">mdi-email</v-icon>
									</template>
									<div>{{ tooltipHeader(item.email, item.shouldEmail, 'Email', 'No address') }}</div>
									<div v-if="item.email">{{ item.email }}</div>
								</v-tooltip>
								<v-tooltip bottom :color="notifIconColor(item.phone, item.shouldSMS)">
									<template v-slot:activator="{ on, attrs }">
										<v-icon v-bind="attrs" v-on="on" class="mr-2" :color="notifIconColor(item.phone, item.shouldSMS)">mdi-message-badge</v-icon>
									</template>
									<div>{{ tooltipHeader(item.phone, item.shouldSMS, 'SMS', 'No number') }}</div>
									<div v-if="item.phone">{{ item.phone }}</div>
								</v-tooltip>
								<v-tooltip bottom :color="notifIconColor(item.deviceInfo, item.shouldPush)">
									<template v-slot:activator="{ on, attrs }">
										<v-icon v-bind="attrs" v-on="on" :color="notifIconColor(item.deviceInfo, item.shouldPush)">mdi-bell-badge</v-icon>
									</template>
									<div>{{ tooltipHeader(item.deviceInfo, item.shouldPush, 'In-app Push', 'Not enrolled') }}</div>
									<div v-if="item.deviceInfo">{{ item.deviceInfo }}</div>
								</v-tooltip>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
				<v-card outlined class="selected-users">
					<v-card-title>Selected Users <v-chip x-small class="ml-2">{{ selectedUsersSorted.length }}</v-chip></v-card-title>
					<v-list dense>
						<v-list-item
							v-for="item in selectedUsersSorted"
							:key="item.id"
						>
							<v-list-item-title>{{ item.name }}</v-list-item-title>
							<v-list-item-icon>
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-icon v-bind="attrs" v-on="on" small class="mr-2">mdi-information</v-icon>
									</template>
									<div>{{  item.role }}</div>
									<div>{{ tooltipHeader(item.email, item.shouldEmail, 'Email', 'No address') }}</div>
									<div v-if="item.email">{{ item.email }}</div>
									<div>{{ tooltipHeader(item.phone, item.shouldSMS, 'SMS', 'No number') }}</div>
									<div v-if="item.phone">{{ item.phone }}</div>
									<div>{{ tooltipHeader(item.deviceInfo, item.shouldPush, 'In-app Push', 'Not enrolled') }}</div>
									<div v-if="item.deviceInfo">{{ item.deviceInfo }}</div>
								</v-tooltip>
							</v-list-item-icon>
						</v-list-item>
					</v-list>
				</v-card>
				<div class="message-compose">
					<v-combobox
						hide-details
						style="grid-area: message-location;"
						outlined
						dense
						:label="$t('chooseENSLocation')"
						:items="availableLocationsFlat"
						item-text="name"
						v-model="messageLocation"
						small-chips
						:search-input.sync="messageLocationSearch"
					>
					<template v-slot:no-data>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>
									No existing locations matching "<strong>{{ messageLocationSearch }}</strong>".
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</template>
					<template v-slot:append-item>
						<v-list-item disabled v-if="messageLocationSearch">
							<v-list-item-content>
								<v-list-item-title>
									Press <kbd>enter</kbd> to set "<strong>{{ messageLocationSearch }}</strong>" as the emergency location.
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</template>
					</v-combobox>
					<v-select
						hide-details
						style="grid-area: message-template;"
						:items="messageTemplates"
						v-model="selectedTemplate"
						item-text="name"
						return-object
						:label="$t('selectMessage')"
						outlined
						dense
					/>
					<v-text-field
						style="grid-area: message-subject;"
						outlined
						dense
						hide-details
						label="Message subject"
						v-model="messageSubject"
					/>
					<v-textarea
						style="grid-area: message-content;"
						v-model="messageContent"
						label="Message content"
						outlined
						dense
						hide-details
					/>
				</div>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="primary"
					@click="send"
					class="mr-2"
					:disabled="!readyToSend"
				>
					{{ $t('send') }}
				</v-btn>
			</v-card-actions>
		</v-card>
		<v-snackbar
			v-model="alert"
			:multi-line="false"
			color="error"
		> {{ errorMessage }}
			<template v-slot:action="{ attrs }">
				<v-btn
					color="white"
					text
					v-bind="attrs"
					@click="alert = false"
				>
					{{ $t('close') }}
				</v-btn>
			</template>
		</v-snackbar>
	</v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import Vue from 'vue';
import ENSMessage from '@/repositories/v2/ENSMessage';
import Location from '@/repositories/v2/Location';
import Organization from '@/repositories/v2/Organization';
import Region from '@/repositories/v2/Region';

const nameCollator = new Intl.Collator(undefined, { numeric: true });

export default {
	name: 'EmergencyDialog',
	components: {
	},
	props: ['dialog', 'selectedObj'],
	data () {
		return {
			footerProps: { 'items-per-page-options': [-1] },
			overlay: false,
			searchTreeView: '',
			searchDataTable: '',
			userHeaders: [
				{ text: 'Name', value: 'name' },
				{ text: 'Region', value: 'region' },
				{ text: 'Location', value: 'location' },
				{ text: 'Send Via', value: 'notificationStatus', sortable: false },
			],
			alert: false,
			errorMessage: [],
			loadingUserList: false,
			loadingTreeView: false,
			isOpen: true,

			availableLocations: [],
			selectedLocations: [],
			availableUsers: {},
			selectedUsers: [],

			messageLocation: null,
			messageLocationSearch: '',
			messageTemplates: [],
			actualSelectedTemplate: null,
			messageSubject: '',
			messageContent: '',
		};
	},
	computed: {
		...mapState({
			selectedClient: state => state.filters.clientId,
		}),
		availableUsersList () {
			return Object.values(this.availableUsers);
		},
		readyToSend () {
			return !!(this.selectedUsers.length && this.messageLocation && this.messageSubject.trim() && this.messageContent.trim());
		},
		availableLocationsFlat () {
			return this.availableLocations.flatMap(region => {
				if (!region.locations.length) {
					return [];
				}

				return [
					{ header: region.name },
					...region.locations,
				];
			});
		},
		selectedUsersSorted () {
			return this.selectedUsers.toSorted((a, b) => nameCollator.compare(a.name, b.name));
		},
		selectedTemplate: {
			get: function () {
				return this.actualSelectedTemplate;
			},
			set: async function (newValue) {
				const oldTemplate = this.actualSelectedTemplate;
				if (
					// No template (or "custom") is selected, and the user has entered content
					(!oldTemplate && (this.messageContent || this.messageSubject)) ||
					// A template is selected, and the user has changed the content
					(oldTemplate && !(this.messageContent === oldTemplate.content && this.messageSubject === oldTemplate.subject))
				) {
					if (!await this.$confirm(
						'If you select a different template, it will overwrite the subject and message you have already entered. Are you sure you want to continue?',
						{ color: 'warning', title: 'Warning' },
					)) {
						return;
					}
				}
				this.actualSelectedTemplate = newValue;
				this.messageSubject = newValue.subject;
				this.messageContent = newValue.content;
			},
		},
	},
	mounted () {
		this.overlay = false;
		this.loadData();
	},
	watch: {
		async selectedLocations (newVal, oldVal) {
			if (!newVal.length) {
				// If `newVal` is empty, clear the user list
				this.availableUsers = {};
				this.selectedUsers = [];
				return;
			}
			this.loadingUserList = true;
			const newlySelectedLocations = newVal.filter(item => {
				// We only do API calls to list users of locations.
				// This will include all regional users that are inherited
				// by the location.
				if (item.type === 'region') { return false; }
				// We assume the user list hasn't changed in the last few
				// seconds/minutes since any previous locations were selected,
				// so we only need to load users for any newly selected locations.
				return !oldVal.includes(item);
			});

			try {
				await Promise.all(newlySelectedLocations.map(async (location) => {
					const locationUsers = await (new Location({ id: location.id }))
						.users()
						.include('role', 'user.mainPhone', 'userPrefs')
						.params({ type: 'ens', exclude: 'operator_login' })
						.$all();
					this.addUsersToTable(locationUsers);
				}));

				const unselectedItems = oldVal.filter(item => !newVal.includes(item));
				// Check if any of the removed locations was the last in its region,
				// and remove users from those regions.
				const unselectedRegions = unselectedItems
					// Get the region IDs of the unselected locations
					.map(item => item.regionId)
					// Check if any other selected locations have the same region ID
					.filter(regionId => !newVal.some(loc => loc.regionId === regionId));
				this.removeUsersForUnselectedLocations(unselectedItems, unselectedRegions);
			} finally {
				this.loadingUserList = false;
			}
		},
	},
	methods: {
		toggleAll () {
			if (this.isOpen) {
				Object.keys(this.$refs).forEach(k => {
					if (this.$refs[k] && this.$refs[k].$attrs['data-open']) {
						this.$refs[k].$el.click();
					}
				});
				this.isOpen = false;
			} else {
				Object.keys(this.$refs).forEach(k => {
					if (this.$refs[k] && !this.$refs[k].$attrs['data-open']) {
						this.$refs[k].$el.click();
					}
				});
				this.isOpen = true;
			}
		},
		close () {
			this.$emit('emergency-dialog-closed');
		},
		isRegion (item) {
			return !!item?.locations;
		},
		hasLocations (region) {
			return !!region.locations?.length;
		},
		async loadData () {
			this.loadingTreeView = true;
			this.overlay = true;
			try {
				this.availableLocations = await ensLocationsWithUniqueKeys(this.selectedClient);
			} finally {
				this.loadingTreeView = false;
			}
			this.messageTemplates = (await (new Organization({ id: this.selectedClient }))
				.messageTemplates()
				.where('type', 'ens')
				.$all())
				.map(template => ({ ...template, subject: template.name }));
			this.messageTemplates.unshift({ name: 'Custom Message (No template)', content: '', subject: '' });
		},
		generatePreviewMessageAndDisclaimer () {
			const previewMessage = `<blockquote class="blockquote">
				<p>${this.messageSubject} at ${this.messageLocation.name ?? this.messageLocation}:<br/>
				${this.messageContent}</p>
				</blockquote>`;

			return `<h3>Message Preview</h3>
			${previewMessage}
			<h3>Disclaimer</h3>
			<p class="body-2"><em>
				Emergency Notification System (ENS) messages may be sent by in-app &quot;push&quot; notifications, SMS (text messages), and/or email.
			</em></p>
			<ul class="body-2">
				<li><em>
					In-app push notifications require the user's explicit consent, via a prompt shown in the mobile application.
					Users are able to revoke this consent at any time through their device settings.
					Carrier network issues may prevent timely delivery of push notifications.
				</em></li>
				<li><em>
					SMS (text message) notifications require the user's consent, which should be collected by the client administrator during the onboarding process.
					Users are able to revoke this consent at any time by sending the word &quot;STOP&quot;, &quot;STOPALL&quot;, &quot;QUIT&quot;, &quot;CANCEL&quot;, &quot;END&quot;, or &quot;UNSUBSCRIBE&quot;.
					Carriers may choose to delay or block SMS messages as part of anti-abuse/spam/phishing protection systems.
					Revocation of consent by a large number of users can result in suspension of the ability to send SMS messages.
				</em></li>
				<li><em>
					Email notifications do not require individual user consent, because nessages may only sent to customer-owned corporate email addresses at verified domains.
					Please ensure that your email systems are configured not to block or filter these messages.
				</em></li>
			</ul>
			`;
		},
		async send () {
			if (!await this.$confirm(this.generatePreviewMessageAndDisclaimer(), {
				buttonTrueText: 'Agree and send',
				buttonFalseText: 'Cancel',
				color: 'primary',
				title: 'ENS Preview and disclaimer',
			})) {
				return;
			}

			try {
				const notifiedLocations = this.selectedLocations.map(location => location.id);

				await (new ENSMessage({
					organization_id: this.selectedClient,
					usersToNotify: this.selectedUsers.map(u => u.id),
					location_id: this.messageLocation.id ?? null, // null for custom location text
					location_text: this.messageLocation.name ?? this.messageLocation,
					notified_locations: notifiedLocations,
					subject: this.messageSubject,
					content: this.messageContent,
					templateUsed: this.actualSelectedTemplate?.id,
				})).save();
				this.$emit('emergency-dialog-closed');
			} catch (e) {
				// TODO: show error
			}
		},
		addUsersToTable (newUsers) {
			newUsers.forEach(u => {
				// TODO: add multiple location/region support
				if (!this.availableUsers[u.user_id]) {
					const shouldEmail = u.emergency_settings?.Email ?? true;
					const shouldSMS = u.emergency_settings?.SMS ?? true;
					const shouldPush = u.emergency_settings?.Push ?? true;
					const user = {
						id: u.user_id,
						name: `${u.given_name} ${u.surname}`,
						role: u.role_name,
						roleId: u.role_id,
						groupOrder: [u.role_order, u.role_id],
						regions: {},
						locations: {},
						email: u.email,
						phone: u.number,
						deviceInfo: u.device_info,
						// TODO: these values might be different for different locations
						shouldEmail,
						shouldSMS,
						shouldPush,
						isSelectable: shouldEmail || shouldSMS || shouldPush,
					};
					switch (u.assignable_type) {
					case 'organization':
						user.regions.ALL = 'ALL';
						user.locations.ALL = 'ALL';
						break;
					case 'region':
						user.regions[u.assignable_id] = this.lookupRegionName(u.assignable_id);
						user.locations.ALL = 'ALL';
						break;
					case 'location': {
						const locRegion = this.lookupLocationRegion(u.assignable_id);
						user.regions[locRegion.id] = locRegion.name;
						user.locations[u.assignable_id] = this.lookupLocationName(u.assignable_id);
					}
					}

					Vue.set(this.availableUsers, u.user_id, user);
				}
			});
		},
		removeUsersForUnselectedLocations (unselectedLocations, unselectedRegionIds) {
			if (!unselectedLocations.length) { return; }

			// For each user in the table, remove all unselected locations
			// from the user object. If the user has no more locations,
			// remove the user from the list.
			const removedUserIds = [];
			Object.keys(this.availableUsers).forEach(userId => {
				const user = this.availableUsers[userId];
				unselectedLocations.forEach(location => {
					Vue.delete(user.locations, location.id);
				});
				switch (Object.keys(user.locations).length) {
				case 0:
					Vue.delete(this.availableUsers, userId);
					removedUserIds.push(user.id);
					break;
				case 1:
					if (user.locations.ALL) {
						// Check for removed regions
						unselectedRegionIds.forEach(regionId => {
							Vue.delete(user.regions, regionId);
						});
						if (!Object.keys(user.regions).length) {
							Vue.delete(this.availableUsers, userId);
							removedUserIds.push(user.id);
						}
					}
					break;
				}
			});

			// Also remove any of the removed users from the selected user list,
			// if present
			this.selectedUsers = this.selectedUsers.filter(user => {
				return !removedUserIds.includes(user.id);
			});
		},
		lookupRegionName (regionId) {
			return this.availableLocations.find(r => r.id === regionId)?.name ?? '';
		},
		lookupLocationRegion (locationId) {
			return this.availableLocations.find(r => r.locations?.some(l => l.id === locationId));
		},
		lookupLocationName (locationId) {
			return this.availableLocations.reduce((prev, r) => {
				return prev || r.locations?.find(l => l.id === locationId)?.name;
			}, null) ?? '';
		},
		notifIconColor (available, enabled) {
			if (available) {
				if (enabled) {
					return 'green';
				}
				return 'red';
			}
			return 'gray';
		},
		tooltipHeader (available, enabled, name, noneMessage) {
			if (available) {
				if (enabled) {
					return name;
				}
				return `${name} (DISABLED)`;
			}
			return `${name}: ${noneMessage}`;
		},
	},
};

async function ensLocationsWithUniqueKeys (organizationId) {
	const response = await Region.where('organization_id', organizationId)
		.include('locations')
		.params({ type: 'simple' })
		.$all();

	return response.map(r => {
		r.type = 'region';
		r.uniqId = `region-${r.id}`;
		r.disabled = r.locations.length === 0;
		r.locations = r.locations.map(l => {
			l.regionId = r.id;
			l.type = 'location';
			l.uniqId = `location-${l.id}`;
			l.disabled = false;
			return l;
		});
		return r;
	});
}
</script>

<style scoped>
.v-dialog > .v-card > .v-card__title {
	background: #F3F4F6;
	color: #000;
	height: 40px;
	padding: 5px 20px;
}
.grid-container .v-card__title {
	font-size: 16px;
}
.user-chip {
	max-width: 100%;  /* or set the width as needed */
}
.outlined-card-text {
	border-radius: 4px; /* Optional: Rounded corners for the outline */
}

/* https://grid.layoutit.com/?id=UGWtZ34 */
.grid-container {
	display: grid;
	grid-template-columns: 4fr 6fr 2fr;
	grid-template-rows: 3fr 2fr;
	gap: 8px 8px;
	grid-auto-flow: row;
	grid-template-areas:
		"location-select user-select selected-users"
		"message-compose message-compose message-compose";
}
.user-select { grid-area: user-select; }
.location-select { grid-area: location-select; }
.selected-users {
	grid-area: selected-users;
	overflow-y: auto;
}
.selected-users .v-list-item {
	min-height: 32px;
}
.selected-users .v-list-item__icon {
	margin-top: 4px;
	margin-bottom: 4px;
}
.message-compose {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr auto;
	gap: 12px 8px;
	grid-auto-flow: row;
	grid-template-areas:
		"message-location message-location"
		"message-template message-subject"
		"message-content message-content";
	grid-area: message-compose;
}
</style>
