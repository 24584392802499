
const state = {
	selectedProduct: 0, // Initialize with a default value
};

const mutations = {
	SET_SELECTED_PRODUCT (state, productId) {
		state.selectedProduct = productId;
	},
};

const actions = {
	setSelectedProduct ({ commit }, productId) {
		commit('SET_SELECTED_PRODUCT', productId);
	},
};

const getters = {
	selectedProduct: state => state.selectedProduct,
};

export default {
	state,
	mutations,
	actions,
	getters,
};
