import Address from './Address';
import Model from './Model';
import Phone from './Phone';
import User from './User';

export default class Region extends Model {
	// Set the resource route of the model
	resource () {
		return 'regions';
	}

	relations () {
		return {
			phones: Phone,
			addresses: Address,
		};
	}

	users () {
		return this.hasMany(User);
	}
}
