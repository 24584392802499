import Address from './Address';
import Model from './Model';
import Phone from './Phone';

export default class User extends Model {
	// Set the resource route of the model
	resource () {
		return 'users';
	}

	relations () {
		return {
			phones: Phone,
			addresses: Address,
		};
	}
}
