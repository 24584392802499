import BranchOffice from '@/lib/models/Client/BranchOffice';
import BranchType from '@/lib/models/Client/BranchType';
import User from '@/lib/models/Client/User';
import { BranchOfficeError } from '@/lib/Errors';
import $http from '../../../plugins/axios';

async function modifyBranch (body, errorMessage) {
	const response = await $http.post('/api/client/managebranchoffices', body);

	if (response.data?.success) {
		return response.data.body;
	} else {
		throw new BranchOfficeError(errorMessage);
	}
}

export default {
	async get () {
		const body = {
			case: 'GET-ALL-BRANCH-OFFICES',
		};

		const response = await $http.post('/api/client/getofficesv2', body);

		if (response.data?.success) {
			const data = response.data.body.organizationobject || [];
			const branchOffices = data.map(br => {
				const branch = new BranchOffice(br);
				return branch;
			});
			return branchOffices;
		} else {
			throw new BranchOfficeError('Error retrieving Branch Offices');
		}
	},
	async activityForBranches (item) {
		const response = await $http.get(`/api/locations/${item}/activity`);
		if (response.status !== 200) {
			throw new BranchOfficeError('Error retrieving Users');
		} else {
			return response.data;
		}
	},
	// async testMode (item) {
	// 	const response = await $http.get(`/api/tasks/location/${item}`);
	// 	if (response.status !== 200) {
	// 		throw new BranchOfficeError('Error retrieving Users');
	// 	} else {
	// 		return response.data;
	// 	}
	// },
	async getBranchesInRegion (regionId) {
		const body = {
			case: 'GET-BRANCH-OFFICES-BY-REGION',
			regionalOfficeId: `${regionId}`,
		};

		const response = await $http.post('/api/client/getbranchbyregionalid', body);

		if (response.data?.success) {
			const data = response.data.body.organizationobject || [];
			const branchOffices = data.map(br => {
				const branch = new BranchOffice(br);
				return branch;
			});
			return branchOffices;
		} else {
			throw new BranchOfficeError('Error retrieving Branch Offices');
		}
	},
	async getBranchById (id) {
		const body = {
			case: 'GET-BRANCH-OFFICE-BY-BRANCHOFFICEID',
			branchOfficeId: `${id}`,
		};

		const response = await $http.post('/api/client/getbranchbyidv2', body);

		if (response.data?.success) {
			const data = response.data.body.organizationobject;
			const branch = new BranchOffice(data[0]);
			return branch;
		} else {
			throw new BranchOfficeError('Error retrieving Branch Offices');
		}
	},
	async getBranchTypes () {
		const body = {
			case: 'GET-BRANCH-OFFICE-TYPES',
		};

		const response = await $http.post('/api/client/getknownoperators', body);

		if (response.data?.success) {
			const data = response.data.body.organizationobject || [];
			const branchTypes = data.map(brt => {
				const branchType = new BranchType(brt);
				return branchType;
			});
			return branchTypes;
		} else {
			throw new BranchOfficeError('Error retrieving Branch Types');
		}
	},
	async store (body) {
		const response = await $http.post(`/api/organizations/${body.organization_id}/locations`, body);
		if (response.status === 201) {
			return response.data.data;
		} else {
			if (response.response.status === 422) {
				return response.response.data;
			} else {
				return response.response.data;
			}
			// throw new BranchOfficeError('Error storing location');
		}
	},
	async updateTestMode (body) {
		const response = await $http.post('/api/testMode', body);
		if (response.status === 201) {
			return response.data.data;
		} else {
			if (response.response.status === 422) {
				return response.response.data;
			} else {
				return response.response.data;
			}
			// throw new BranchOfficeError('Error storing location');
		}
	},
	async deleteTestMode (body) {
		const response = await $http.delete(`/api/testMode/${body.id}`, body);
		if (response.status === 201) {
			return response.data.data;
		} else {
			if (response.response.status === 422) {
				return response.response.data;
			} else {
				return response.response.data;
			}
			// throw new BranchOfficeError('Error storing location');
		}
	},
	async update (object) {
		const response = await $http.patch(`/api/locations/${object.id}`, object);
		if (response.status === 200) {
			return response.data;
		} else {
			if (response.response.status !== 200) {
				return response.response.data;
			}
			throw new BranchOfficeError('Error updating location');
		}
	},
	async setActiveLocation (object) {
		const response = await $http.patch(`/api/locations/${object}/restore`);
		if (response.status === 200) {
			return response.data;
		} else {
			if (response.response.status === 422) {
				return response.response.data;
			}
			throw new BranchOfficeError('Error updating location');
		}
	},
	async delete (object) {
		const response = await $http.delete(`/api/locations/${object.id}`, object);
		if (response.status !== 204) {
			throw new BranchOfficeError('Error updating location');
		}
	},
	async updateBranchDetails (object) {
		object.case = 'UPDATE-BRANCH-OFFICE-DETAILS';

		return modifyBranch(object, 'Error updating Branch Details');
	},
	async updateBranchSchedule (object) {
		object.case = 'UPDATE-BRANCH-OFFICE-WEEKSCHEDULE';

		return modifyBranch(object, 'Error updating Branch Office Schedule');
	},
	async updateBranchHolidays (object) {
		object.case = 'UPDATE-BRANCH-OFFICE-HOLIDAYS';

		return modifyBranch(object, 'Error updating Branch Holidays');
	},
	async getBranchEmployees (id) {
		const body = {
			param_branchid: `${id}`,
		};

		const response = await $http.post('/api/client/getbranchemployees', body);

		if (response.data?.success) {
			const data = response.data.body.userobject || [];
			const branchEmployees = data.map(be => {
				const user = new User(be);
				return user;
			});
			return branchEmployees;
		} else {
			return [];
		}
	},
	async updateTimers (object) {
		object.case = 'UPDATE-BRANCH-OFFICE-TIMERS';

		return modifyBranch(object, 'Error updating Branch Timers');
	},
	async setBranchTestMode (object) {
		object.case = 'SET-BRANCH-MODE';

		const response = await $http.post('/api/client/setbranchmode', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new BranchOfficeError('Error updating Branch mode');
		}
	},

	async getSimplifiedLocations (filters) {
		const formattedFilters = Object.entries(filters)
			.map(([key, value]) => `filter[${key}]=${value}`)
			.join('&');

		const response = await $http.get(`/api/locations/getSimplified?${formattedFilters}`);

		if (response.status !== 200) {
			throw new BranchOfficeError('Error retrieving location');
		}

		return response.data;
	},
};
