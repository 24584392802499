import Repository from '@/repositories/RepositoryFactory';

const Schedule = Repository.get('client', 'schedule');
const TimedSchedule = Repository.get('client', 'timedSchedule');
const UntimedSchedule = Repository.get('client', 'untimedSchedule');
const ScheduleV1 = Repository.get('client', 'scheduleV1');

export default {
	namespaced: true,
	state: {
		schedules: [],
		schedule: {},
	},
	getters: {

	},
	mutations: {
		LOAD_SCHEDULES: (state, payload) => {
			state.schedules = payload;
		},
		LOAD_SCHEDULE: (state, payload) => {
			state.schedule = payload;
		},
		LOAD_SCHEDULE_TIMED: (state, payload) => {
			state.schedule = payload;
		},
		LOAD_SCHEDULE_UNTIMED: (state, payload) => {
			state.schedule = payload;
		},
	},
	actions: {
		async get ({ commit }) {
			commit('LOAD_SCHEDULES', await Schedule.all());
		},
		// async getScheduleById ({ commit }, id) {
		// 	commit('LOAD_SCHEDULE', await Schedule.(id));
		// },
		async getScheduleByOrganization ({ commit }, id) {
			commit('LOAD_SCHEDULE', await ScheduleV1.getScheduleRegion(id));
		},
		async getScheduleByRegion ({ commit }, id) {
			commit('LOAD_SCHEDULE', await ScheduleV1.getScheduleLocation(id));
		},
		async store ({ commit }, object) {
			const res = new Schedule(object);
			commit('LOAD_SCHEDULE', await res.save());
			return res;
		},
		async updateV1 ({ commit }, object) {
			const res = await ScheduleV1.update(object);
			return res;
		},
		async update ({ commit }, object) {
			const model = await Schedule.find(object.id);
			model.name = object.name;
			model.open_task_id = object.open_task_id;
			model.close_task_id = object.close_task_id;
			model.open_perform_sunday = object.open_perform_sunday;
			model.open_perform_monday = object.open_perform_monday;
			model.open_perform_tuesday = object.open_perform_tuesday;
			model.open_perform_tuesday = object.open_perform_tuesday;
			model.open_perform_wednesday = object.open_perform_wednesday;
			model.open_perform_thursday = object.open_perform_thursday;
			model.open_perform_friday = object.open_perform_friday;
			model.open_perform_saturday = object.open_perform_saturday;
			model.close_perform_sunday = object.close_perform_sunday;
			model.close_perform_monday = object.close_perform_monday;
			model.close_perform_tuesday = object.close_perform_tuesday;
			model.close_perform_wednesday = object.close_perform_wednesday;
			model.close_perform_thursday = object.close_perform_thursday;
			model.close_perform_friday = object.close_perform_friday;
			model.close_perform_saturday = object.close_perform_saturday;
			model.patch();
		},
		async storeTimedSchedule ({ commit }, object) {
			const res = new TimedSchedule(object);
			commit('LOAD_SCHEDULE_TIMED', await res.save(object));
		},
		async storeUntimedSchedule ({ commit }, object) {
			const res = new UntimedSchedule(object);
			commit('LOAD_SCHEDULE_UNTIMED', await res.save(object));
		},
	},
};
