import Repository from '@/repositories/RepositoryFactory';
import Location from '@/repositories/v2/Location';

const Branch = Repository.get('client', 'branch');
const BranchV1 = Repository.get('client', 'branchV1');

export default {
	namespaced: true,
	state: {
		branchOffices: [],
		branch: null,
		branchTypes: [],
		branchEmployees: [],
		branchActivities: [],
		branchTestMode: [],
		branchSimplified: [],
		locationOfficeUsers: [],
		loadingThird: false,
	},
	mutations: {
		LOAD_BRANCH_OFFICES: (state, payload) => {
			state.branchOffices = payload.data;
		},
		LOAD_BRANCH_OFFICES_SIMPLIFIED: (state, payload) => {
			state.branchSimplified = payload.data;
		},
		LOAD_BRANCH_OFFICES_BY_REGIONAL_ID: (state, payload) => {
			state.branchOffices = payload;
		},
		LOAD_BRANCH_OFFICES_FOR_DROPDOWN: (state, payload) => {
			state.branchOfficesForDropdown = payload;
		},
		LOAD_BRANCH_BY_ID: (state, payload) => {
			state.branch = payload.data;
		},
		LOAD_BRANCH_TYPES: (state, payload) => {
			state.branchTypes = payload;
		},
		LOAD_BRANCH_EMPLOYEES: (state, payload) => {
			state.branchEmployees = payload;
		},
		LOAD_BRANCH_ACTIVITIES: (state, payload) => {
			state.branchActivities = payload;
		},
		SET_LOADING_THIRD (state, loading) {
			state.loadingThird = loading;
		},
		LOAD_USERS: (state, payload) => {
			state.locationOfficeUsers = payload;
		},
		// LOAD_TEST_MODE: (state, payload) => {
		// 	state.branchTestMode = payload;
		// },
	},
	actions: {
		async get ({ commit }) {
			commit('LOAD_BRANCH_OFFICES', await Branch.all());
		},
		async getBrnaches ({ commit }, body) {
			commit('LOAD_BRANCH_OFFICES', await Branch.where('trashed', 'with').where('organization_id', `${body.id}`).get());
		},
		async getBranchesSimplified ({ commit }, body) {
			const response = await BranchV1.getSimplifiedLocations({
				trashed: 'with',
				organization_id: body.id,
			});

			commit('LOAD_BRANCH_OFFICES_SIMPLIFIED', response);
		},
		async activityForBranches ({ commit }, item) {
			commit('LOAD_BRANCH_ACTIVITIES', await BranchV1.activityForBranches(item));
		},
		// async testMode ({ commit }, item) {
		// 	commit('LOAD_TEST_MODE', await BranchV1.testMode(item));
		// },
		async getBrnachesForSecurity ({ commit }) {
			commit('LOAD_BRANCH_OFFICES', await Branch.where('trashed', 'with').get());
		},
		async getAll ({ commit }, body) {
			if (body.array.length > 0) {
				commit('LOAD_BRANCH_OFFICES', await Branch.include(body.array).where('trashed', 'with').where('organization_id', `${body.id}`).get());
			} else {
				commit('LOAD_BRANCH_OFFICES', await Branch.where('trashed', 'with').where('organization_id', `${body.id}`).get());
			}
		},
		async getAllForSecurity ({ commit }, body) {
			if (body.array.length > 0) {
				commit('LOAD_BRANCH_OFFICES', await Branch.include(body.array).where('trashed', 'with').get());
			} else {
				commit('LOAD_BRANCH_OFFICES', await Branch.where('trashed', 'with').get());
			}
		},
		async getAllForUser ({ commit }, body) {
			commit('LOAD_BRANCH_OFFICES', await Branch.include('region').where('trashed', 'with').where('organization_id', `${body.id}`).get());
		},
		async getBranchById ({ commit }, id) {
			commit('LOAD_BRANCH_BY_ID', await Branch.find(id));
		},
		async getBranchByRegionalId ({ commit }, id) {
			commit('LOAD_BRANCH_OFFICES_BY_REGIONAL_ID', await Branch.where('region_id', id).include('schedule.*').get());
		},
		async getBranchDetails ({ commit }, object) {
			const branchData = await Branch
				.include(
					'phones',
					'addresses',
					'schedule.*',
					'region',
					'type',
					'all_survey_locations',
					'activeTestMode',
					'securityOffice.phones',
				)
				.where('trashed', 'with')
				.params({ show_holidays: 1 })
				.find(object.id);
			commit('LOAD_BRANCH_BY_ID', branchData);
			return branchData;
		},
		async getLocationDetailsUsers ({ commit }, id) {
			commit('SET_LOADING_THIRD', true);
			const locationUsers = await (new Location({ id })).users().$get();
			commit('LOAD_USERS', locationUsers);
			commit('SET_LOADING_THIRD', false);
		},
		async store ({ commit }, object) {
			const res = await new Branch(object);
			res.save();
			return res;
		},
		async storeV1 ({ commit }, object) {
			const res = await BranchV1.store(object);
			return res;
		},
		async updateTestMode ({ commit }, object) {
			const res = await BranchV1.updateTestMode(object);
			return res;
		},
		async update ({ commit }, object) {
			const model = object;
			model.save();
		},
		async updateV1 ({ commit }, object) {
			const res = await BranchV1.update(object);
			return res;
		},
		async setActiveLocation ({ commit }, object) {
			const res = await BranchV1.setActiveLocation(object);
			return res;
		},
		async delete ({ commit }, object) {
			await BranchV1.delete(object);
		},
	},
};
