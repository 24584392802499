import { setBaseURL } from '../../plugins/axios';
import { setUser } from '@sentry/vue';
import { redirectToAuth } from '@/router/authentication';
import { getUserInfo } from '@/repositories/v2/security';

let loginInProgress = false;
let logoutInProgress = false;

export default {
	namespaced: true,
	state: {
		apiServer: process.env.VUE_APP_ACCOUNT_SERVER,
		liveServer: null, // TODO: Implement this
		userInfo: null,
		capabilities: {},
	},
	mutations: {
		LOAD_USER (state, payload) {
			state.userInfo = payload.user;
			state.liveServer = payload.live;
			state.capabilities = payload.caps;
			setUser({
				id: payload.user.id,
				email: payload.user.email,
			});
		},
		SET_API_SERVER (state, server) {
			state.apiServer = server;
			localStorage.apiServer = server;
			setBaseURL(server);
		},
		LOGOUT (state) {
			localStorage.clear();
			state.apiServer = process.env.VUE_APP_ACCOUNT_SERVER;
			state.liveServer = null;
			setBaseURL(process.env.VUE_APP_ACCOUNT_SERVER);
			setUser(null);
		},
		initialize (state) {
			if (localStorage.apiServer) {
				if (!localStorage.apiServer.startsWith('http')) {
					localStorage.apiServer = `https://${localStorage.apiServer}`;
				}

				state.apiServer = localStorage.apiServer;
			}

			setBaseURL(state.apiServer);
		},
	},
	actions: {
		async loadUserData ({ commit }) {
			// Prevent multiple calls to load user data at the same time
			if (loginInProgress) {
				return;
			}

			try {
				loginInProgress = true;

				const userInfo = await getUserInfo();
				commit('LOAD_USER', userInfo);
				commit('socket/setConfig', userInfo.pusher, { root: true });
			} finally {
				loginInProgress = false;
			}
		},
		async doLogout ({ commit }, reason) {
			// Prevent loops in logout
			if (logoutInProgress) {
				return;
			}

			logoutInProgress = true;

			// Commit here allows other parts of the application to get logout notifications
			// TODO: Do we actually need this, since we are now redirecting to a different login site?
			commit('LOGOUT');
			await redirectToAuth({ direction: 'logout', reason });

			logoutInProgress = false;
		},
	},
	getters: {
		capabilitiesLoaded (state) {
			return Object.keys(state.capabilities).length > 0;
		},
		getLiveInfo (state) {
			return state.liveServer;
		},
		hasAnyCapability (state) {
			return (needed) => {
				// If `needed` is undefined or empty, that means access is allowed
				if (needed?.length > 0) {
					return needed.some(c => c in state.capabilities);
				}
				return true;
			};
		},
	},
};
