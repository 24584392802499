import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules';
import createAlarmNotificationPlugin from '@/store/plugins/alarm-notification-sound';
import createPusherPlugin from '@/store/plugins/pusher';

Vue.use(Vuex);

const store = new Vuex.Store({
	modules,
	plugins: [
		createAlarmNotificationPlugin(),
		createPusherPlugin(),
	],
	state: {
		barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
		drawer: null,
		pageSize: { height: 500, width: 500 },
		// roles: [],
		organizations: [],
		selectedOrganization: null,
		// securityOffices: [],
		// users: [],
		// branches: [],
		branchStructure: [],
	},
	getters: {
		pageHeight: (state) => (add = 0) => { return add + state.pageSize.height + 'px'; },
	},
	mutations: {
		SET_DRAWER (state, payload) {
			state.drawer = payload;
		},
		SET_SCREEN_SIZE (state, payload) {
			state.pageSize = payload.height > 555 ? { height: payload.height - 350, width: payload.width } : { height: 200, width: payload.width };
		},

		LOAD_ORGANIZATIONS: (state, payload) => {
			state.organizations = payload;
		},

		LOAD_ORGANIZATIONS_ADMIN: (state, payload) => {
			state.organizations = payload;
		},

		GET_ORGANIZATION_ADMIN: (state, payload) => {
			state.selectedOrganization = payload;
		},

		RESET_SELECTED_ORGANIZATION_ADMIN: (state) => {
			const defaultObject = {
				Id: null,
				Name: null,
				Address: null,
				City: null,
				State: null,
				ZipCode: null,
				Country: null,
				PhoneNumber: null,
				Status: null,
				StatusId: null,
				PrimaryContact: {
					Name: null,
					Surname: null,
					Email: null,
					Phone: null,
				},
				SecondaryContact: {
					Name: null,
					Surname: null,
					Email: null,
					Phone: null,
				},
				AdminData: {
					Name: null,
					Surname: null,
					Phone: null,
					Code: null,
					Email: null,
				},
				Dealers: [],
				SecurityOffices: [],
			};
			state.selectedOrganization = defaultObject;
		},

		GET_ROLES_ADMIN: (state, payload) => {
			state.roles = payload;
		},

		GET_SECURITY_OFFICES_ADMIN: (state, payload) => {
			state.securityOffices = payload;
		},

		GET_EMPLOYEES_ADMIN: (state, payload) => {
			state.users = payload;
		},

		UPDATE_CLIENT_ADMINS_TO_ORGANIZATION: (state, payload) => {
			state.selectedOrganization.AdminData = payload;
		},

		UPDATE_SECURITY_OFFICES_TO_ORGANIZATION: (state, payload) => {
			state.selectedOrganization.SecurityOffices = payload;
		},

		UPDATE_DEALERS_TO_ORGANIZATION: (state, payload) => {
			state.selectedOrganization.Dealers = payload;
		},

		SET_BRANCH_STRUCTURE (state, payload) {
			state.branchStructure = payload.map(organization => {
				return {
					orgId: organization.OrganizationId,
					orgName: organization.OrganizationName,
					regions: organization.Regions === null ? null : organization.Regions.map(region => {
						return {
							regId: region.RegionalOfficeId,
							regName: region.RegionalOfficeName,
							branches: region.Branches === null ? null : region.Branches.map(branch => {
								return {
									branchId: branch.BranchOfficeId,
									branchName: branch.BranchOfficeName,
								};
							}),
						};
					}),
				};
			});
		},

		addRinging () {
			// NO-OP for ringing plugin
		},
		removeRinging () {
			// NO-OP for ringing plugin
		},
	},
});

export default store;
