import axios from 'axios';
import { Model } from 'vue-api-query';
import { APIServerError } from '../lib/Errors';

const $http = axios.create({
	withCredentials: true,
});

Model.$http = $http;

/**
 * Set up interceptors for catching permission problems and CSRF Tokens
 *
 * @param {*} router Vue-Router
 * @param {*} param1 Vuex Store
 */
export function setupInterceptors ({ commit, dispatch, state }) {
	$http.interceptors.request.use((request) => {
		if ($http.defaults.baseURL == null || $http.defaults.baseURL.length === 0) {
			throw new APIServerError('Could not find server to connect');
		}

		// This used to be built-in to axios, but they turned it off.
		// See https://github.com/axios/axios/issues/6047
		// and https://github.com/axios/axios/issues/6006
		if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(request.method?.toUpperCase())) {
			// debugger;
			const token = document.cookie.split(';')
				.map(cookie => cookie.split('=', 2))
				.find(cookieKV => cookieKV[0].trim() === 'XSRF-TOKEN')?.[1];
			if (token) {
				request.headers['X-XSRF-TOKEN'] = decodeURIComponent(token);
			}
		}

		return request;
	});

	$http.interceptors.response.use((response) => {
		// If we should be redirected to a dev server, the production
		// server issues a 307 redirect. This tells the browser to POST
		// the same request to the new server. We detect this here by checking
		// if the `response` URL is the same or different from the base URL we already have
		// since the browser does not expose the redirect to us.
		// `origin` gives us `protocol://host:port`
		const responseServer = new URL(response.request.responseURL).origin;
		if (state.authentication.apiServer !== responseServer) {
			commit('authentication/SET_API_SERVER', responseServer);
		}

		return response;
	}, (error) => {
		if (error instanceof APIServerError || error.response?.status === 401) {
			dispatch('authentication/doLogout', 'expired');
		}
		return error;
	});
}

export function setBaseURL (url) {
	$http.defaults.baseURL = url;
}

export default $http;
