import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { addBreadcrumb } from '@sentry/vue';
import { authorizeBroadcastChannel } from '@/repositories/v2/security';

var echo;

Pusher.log = (msg) => {
	if (msg.includes('"event":"pusher:ping"') || msg.includes('"event":"pusher:pong"')) {
		return;
	}

	addBreadcrumb({
		category: 'pusher',
		message: msg,
		level: 'info',
	});
};

function setup (store, settings) {
	echo = new Echo({
		Pusher,
		broadcaster: 'pusher',
		key: settings.config.key,
		wsHost: settings.config.host,
		wssPort: settings.config.port,
		forceTLS: true,
		encrypted: true,
		enabledTransports: ['wss', 'ws'],
		cluster: '',
		channelAuthorization: {
			customHandler: authorizeBroadcastChannel,
		},
	});

	settings.channels.forEach(name => {
		echo.private(name).listen('.live.event', (e) => processLiveEvent(store, e))
			.listen('.import.location.progress', (e) => progressLocationImport(store, e))
			.listen('.import.user.progress', (e) => progressUserImport(store, e));
	});
}

function progressLocationImport (store, event) {
	store.commit('dashboard/LOCATION_IMPORT_STATUS', event);
}

function progressUserImport (store, event) {
	store.commit('dashboard/USER_IMPORT_STATUS', event);
}

function processLiveEvent (store, event) {
	store.commit('dashboard/ADD_NOTIFICATION', event);
}

export default function createPusherPlugin (opts = {}) {
	return function (store) {
		const unsubscribe = store.subscribe((mutation, state) => {
			switch (mutation.type) {
			case 'socket/setConfig':
				if (!echo) {
					setup(store, mutation.payload);
				}
				break;
			case 'authentication/LOGOUT':
			case 'logout':
				unsubscribe();
				if (echo) {
					echo.disconnect();
					echo = null;
				}
				store.dispatch('authentication/doLogout', 'server');
				break;
			}
		});
	};
}
