import { NotificationError, ReportError } from '@/lib/Errors';
import $http from '../../../plugins/axios';

async function getNotifications (payload, typeName) {
	const response = await $http.get('/api/client/getnotificationsv2', { params: payload });

	if (response.data?.success) {
		const body = response.data.body.notificationobject || [];
		if (body === null) {
			return [];
		}
		return body;
	}

	throw new NotificationError(`Error retrieving ${typeName} Notifications`);
}

export default {
	async getAllNotifications (object) {
		object.case = 'GET-ALL-NOTIFICATIONS-WEB';

		return getNotifications(object, 'Normal');
	},
	async getNotificationById (object) {
		object.case = 'GET-NOTIFICATION-BY-ID';

		return getNotifications(object, 'Normal');
	},
	async getAlarmNotifications (object) {
		object.case = 'GET-TROUBLE-NOTIFICATIONS-WEB';

		return getNotifications(object, 'Alarm');
	},
	async getPanicNotifications (object) {
		object.case = 'GET-PANIC-NOTIFICATIONS-WEB';

		return getNotifications(object, 'Panic');
	},
	async getAnomalyNotifications (object) {
		object.case = 'GET-ALL-NOTIFICATIONS-NOT-DONES-WEB';

		return getNotifications(object, 'Anomaly');
	},
	async getActivityNotifiedUser (id) {
		const response = await $http.get(`/api/reports/notifiedUsers/${id}`);
		if (response.status === 200) {
			return response.data;
		} else {
			throw new ReportError('Error retrieving Timer Reports');
		}
	},
	async getEmergencyNotifications (object) {
		object.case = 'GET-EMERGENCY-NOTIFICATIONS-WEB';

		return getNotifications(object, 'Emergency');
	},
	async getEnsUser (id) {
		const response = await $http.get(`/api/ens/usersToNotify/${id}`);

		if (response.status === 200) {
			return response.data.data;
		} else {
			throw new NotificationError('Error retrieving ENS Notifications');
		}
	},
	async getEnsRegionsAndLocations (id) {
		const response = await $http.get(`/api/ens/getENSWithoutUsers/${id}`);

		if (response.status === 200) {
			return response.data.data;
		} else {
			throw new NotificationError('Error retrieving ENS Notifications');
		}
	},
	async getCloseOutCategories () {
		const response = await $http.get('/api/listCloseOutCategories');

		if (response.status === 200) {
			return response.data.data;
		} else {
			throw new NotificationError('Error retrieving ENS Notifications');
		}
	},
	async markAllAsRead (object) {
		const response = await $http.post('/api/client/getnotifications', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new NotificationError('Error marking notifications as read');
		}
	},
	async markAsReadNotification (id) {
		const body = {
			case: 'READ-NOTIFICATION-BY-ID',
			taskid: id,
		};

		const response = await $http.post('/api/client/getnotifications', body);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new NotificationError('Error marking notification as read');
		}
	},
	async sendEnsMessage (object) {
		const response = await $http.post('/api/ens/send', object);
		if (response.status === 204) {
			return response.data;
		} else {
			throw new NotificationError('Error sending ENS message');
		}
	},
	async updateNotificationSettings (object) {
		object.case = 'UPDATE-MY-NOTIFICATIONS-SETTINGS';
		const response = await $http.post('/api/client/updatenotificationsettingsweb', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new NotificationError('Error sending ENS message');
		}
	},
	async getRegionAndLocation (id) {
		const response = await $http.get(`/api/organizations/${id}?include=regions.locations`);
		if (response.status === 200) {
			return response.data.data;
		} else {
			throw new NotificationError('Error sending ENS message');
		}
	},
	async getNotificationSettingForUser (object) {
		object.case = 'GET-MY-NOTIFICATIONS-SETTINGS';
		const response = await $http.post('/api/client/updatenotificationsallowed', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new NotificationError('Error retrieving notification settings');
		}
	},
	async getNotificationsAllowed (object) {
		object.case = 'GET-MY-NOTIFICATIONS-ALLOWED';
		const response = await $http.post('/api/client/updatenotificationsallowed', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new NotificationError('Error retrieving notification settings');
		}
	},
	async updateNotificationsAllowed (object) {
		object.case = 'UPDATE-MY-NOTIFICATIONS-ALLOWED';
		const response = await $http.post('/api/client/updatenotificationsallowed', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new NotificationError('Error updating notification settings');
		}
	},
};
