module.exports = class SummaryReport {
	constructor (object) {
		this.Openings = object.Openings;
		this.Closings = object.Closings;
		this.Service = object.Service;
		this.OpeningsEarly = object.OpeningsEarly;
		this.ClosingsEarly = object.ClosingsEarly;
		this.OpeningsLate = object.OpeningsLate;
		this.ClosingsLate = object.ClosingsLate;
		this.OpeningsOnTime = object.OpeningsOnTime;
		this.ClosingsOnTime = object.ClosingsOnTime;
		this.NotOpened = object.NotOpened;
		this.AutoClosed = object.AutoClosed;
		this.Panic = object.Panic;
		this.Trouble = object.Trouble;
		this.Emergency = object.Emergency;
		this.SecurityAlerts = object.SecurityAlerts;
		this.EmergencyData = object.EmergencyData;
	}
};
