import Organization from '@/lib/models/Client/Organization';
import $http from '../../../plugins/axios';

import { OrganizationError, RegionalOfficeError } from '@/lib/Errors';

export default {
	async get () {
		const body = {
			case: 'GET-ALL-ORGANIZATIONS',
		};

		const response = await $http.post('/api/client/getorganizations', body);

		if (response.data?.success) {
			const data = response.data.body.organizationobject || [];
			const organizations = data.map(org => {
				const organization = new Organization(org);
				return organization;
			});
			return organizations;
		} else {
			throw new OrganizationError('Error retrieving Organizations');
		}
	},

	async getBranchStructure () {
		const body = {
			case: 'GET-ALL-BRANCH-STRUCTURE',
		};

		const response = await $http.post('/api/client/getstatistics', body);

		if (response.data?.success) {
			return response.data.body.organizationobject;
		} else {
			throw new OrganizationError('Error getting branch structure');
		}
	},

	// Storing Client this way, because I could not catch the 422 Unprocessable Entity response using 'vue-api-query'
	async store (body) {
		const response = await $http.post('/api/organizations', body);
		if (response.status === 201) {
			return response.data;
		} else {
			if (response.response.status === 422) {
				return response.response.data;
			}
			throw new OrganizationError('Error getting branch structure');
		}
	},
	async update (body) {
		const response = await $http.patch(`/api/organizations/${body.id}`, body);
		if (response.status === 200) {
			return response.data;
		} else {
			if (response.response.status === 422) {
				return response.response.data;
			}
			throw new OrganizationError('Error getting branch structure');
		}
	},
	async setActiveClient (object) {
		const response = await $http.patch(`/api/organizations/${object}/restore`);
		if (response.status === 200) {
			return response.data;
		} else {
			if (response.response.status === 422) {
				return response.response.data;
			}
			throw new RegionalOfficeError('Error updating location');
		}
	},
	async delete (body) {
		const response = await $http.delete(`/api/organizations/${body.id}`, body);
		if (response.status !== 204) {
			throw new OrganizationError('Error getting branch structure');
		}
	},
	async storeDomainsV1 (body) {
		const response = await $http.post(`api/organizations/${body.organizationId}/domains`, body);
		if (response.status === 200 || response.status === 201) {
			return response.data;
		} else {
			if (response.response.status === 422) {
				return response.response.data;
			}
			throw new OrganizationError('Error getting branch structure');
		}
	},
	async getType (id) {
		const response = await $http.get(`api/locations/${id}/types`);
		if (response.status === 200 || response.status === 201) {
			return response.data;
		} else {
			throw new OrganizationError('Error getting branch structure');
		}
	},
	async deleteDomainsV1 (id) {
		const response = await $http.delete(`api/domains/${id}`);
		if (response.status === 200) {
			return response.data;
		} else {
			throw new OrganizationError('Error getting branch structure');
		}
	},
	async storeScheduleV1 (body) {
		const response = await $http.post(`api/organizations/${body.organization_id}/schedules`, body);
		if (response.status === 201) {
			return response.data;
		} else {
			if (response.response.status === 422) {
				return response.response.data;
			}
			throw new OrganizationError('Error getting branch structure');
		}
	},
	async updateScheduleV1 (body) {
		const response = await $http.patch(`api/schedules/${body.default_schedule_id}`, body);
		if (response.status === 200) {
			return response.data;
		} else {
			if (response.response.status === 422) {
				return response.response.data;
			}
			throw new OrganizationError('Error getting branch structure');
		}
	},
	async getFeatures (id) {
		const response = await $http.get(`api/organizations/${id}/features`);
		if (response.status === 200 || response.status === 201) {
			return response.data;
		} else {
			throw new OrganizationError('Error getting features for organization');
		}
	},
	async assignDeasignFeature (body) {
		const response = await $http.post(`api/organizations/${body.id}/assignDeassignFeatures`, body);
		if (response.status === 200 || response.status === 201) {
			return response.data;
		} else {
			throw new OrganizationError('Error getting features for organization');
		}
	},
};
