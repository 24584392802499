import { TaskError } from '@/lib/Errors';
import $http from '../../../plugins/axios';

export default {
	async dropActiveTask (body) {
		body.case = 'DELETE-ACTIVETASK-BY-ACTIVEID';

		const response = await $http.post('/api/client/dropactivetask', body);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new TaskError('Error clearing task');
		}
	},
	async activateTask (body) {
		body.case = 'ACTIVATE-TASKING';

		const response = await $http.post('/api/client/activatetask', body);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new TaskError('Error activating task');
		}
	},
	async update (body) {
		const response = await $http.patch(`api/tasks/${body.id}`, body);
		if (response.status === 200) {
			return response.data;
		} else {
			if (response.response.status === 405) {
				return response.response.data;
			}
			throw new TaskError('Error getting branch structure');
		}
	},
	async updateTask (body) {
		const response = await $http.patch(`api/tasks/${body.id}`, body);
		if (response.status === 201 || response.status === 200) {
			return response.data;
		} else {
			if (response.status === 405) {
				return response.data;
			}
			throw new TaskError('Error getting branch structure');
		}
	},
	async updateChecklist (body) {
		const response = await $http.post(`api/schedules/assignOrDeassignChecklist/${body.schedule_id}`, body);
		if (response.status === 200) {
			return response.data;
		} else {
			if (response.response.status === 405) {
				return response.response.data;
			}
			throw new TaskError('Error getting branch structure');
		}
	},
};
