<template>
  <v-app-bar
      id="app-bar"
      absolute
      app
      flat
      color="transparent"
      height="75"
  >
    <!-- TODO: Support for mobile in future -->
    <v-btn
        class="mr-3"
        elevation="1"
        fab
        small
        @click="setDrawer(!drawer)"
    >
      <v-icon v-if="drawer">
        mdi-dots-vertical
      </v-icon>

      <v-icon v-else>
        mdi-view-dashboard
      </v-icon>
    </v-btn>
    <template v-if="canSwitchCustomers">
      <v-combobox
				hide-details
				:items="organizationName"
				v-model="selectedOrganization"
				item-text="name"
				item-value="id"
				outlined
				dense
				full-width
				@change="updateSelectedProduct"
				:label="$t('client')"
				:search-input.sync="messageLocationSearch"
				@input="lostFocus"
      >
        <template v-slot:item="{ item }">
          <v-list-item-avatar class="px-0 py-0 my-0 mx-2">
            <v-img
                v-if="item.product_id === 2"
                src="@/icons/safebanker.svg"
                max-width="25"
                max-height="25"
            />
            <v-img
                v-else-if="item.product_id === 3"
                src="@/icons/saferesource.svg"
                max-width="25"
                max-height="25"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <template v-slot:selection="{ item }">
          <v-list-item-avatar class="px-0 py-0 my-0 mx-0">
            <v-img
                v-if="item.product_id === 2"
                src="@/icons/safebanker.svg"
                max-width="25"
                max-height="25"
            />
            <v-img
                v-else-if="item.product_id === 3"
                src="@/icons/saferesource.svg"
                max-width="25"
                max-height="25"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </template>
      </v-combobox>
    </template>
    <v-spacer />
    <Profile :dialog="profileDialog" @user-profile-closed="profileDialog=false"></Profile>
    <Console :dialog="consoleDialog" @console-dialog-closed="consoleDialog=false"></Console>
    <RangePicker opens="right" :startDate="filterStartDate" :endDate="filterEndDate" @change="updateVuex"></RangePicker>
    <v-menu
        bottom
        left
        offset-y
        origin="top right"
        transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
            class="ml-2"
            min-width="0"
            v-bind="attrs"
            v-on="on"
            id="mdi-account"
            rounded
        >
          <span class="mr-3">{{ avatartInitials }}</span>
          <v-icon>mdi-account-circle</v-icon>
        </v-btn>
      </template>

      <v-list :tile="false" nav dense>
        <template>
          <div style="text-align: center">
            <span v-if="authentication.userObject">{{ roleUser }}</span>
            <v-divider></v-divider>
          </div>
          <v-list-item-group
          >
            <v-list-item
                v-for="(item, i) in userMenu"
                :key="i"
                @click="menuActionClick(item.action)"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </template>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
// Components
import Profile from '@/views/components/Profile';
import Console from '@/views/components/Console';
import { PRESETS } from '@/utils/dates';
import RangePicker from '@/components/globalFilter/components/Picker.vue';

// Utilities
import { mapState, mapMutations } from 'vuex';
import { SAFE_RESOURCE } from '@/themes/saferesource/colors';
import { SAFE_BANKER } from '@/themes/safebanker/colors';
import { SAFE_SECURITY } from '@/themes/safesecurity/colors';
import { EventBus } from '@/main'; // Update the path accordingly
import Organization from '@/repositories/v2/Organization';

export default {
	name: 'DashboardCoreAppBar',
	components: {
		Profile,
		Console,
		RangePicker,
	},

	props: {
		value: {
			type: Boolean,
			default: false,
		},
	},

	data: () => ({
		messageLocationSearch: '',
		selectedProduct: null,
		createdClient: null,
		filterStartDate: '',
		filterEndDate: '',
		profileDialog: false,
		userRole: null,
		consoleDialog: false,
		filter: false,
		temp: false,
		organizationName: [],
		selectedOrganization: null,
		inputProps: { solo: true, style: { width: '240px' } },
		menuProps: { offsetY: true, closeOnContentClick: false },
		presets: PRESETS,
		previousSelectedOrganization: null,
	}),
	computed: {
		...mapState(['drawer']),
		...mapState({
			startDate: state => state.filters.startDate,
			endDate: state => state.filters.endDate,
			authentication: state => state.authentication,
			selectedClient: state => state.filters.clientId,
			organizations: state => state.organisation.organizations,
			roleId: state => state.authentication.roleId,
			clientProductId: state => state.authentication.userInfo,
		}),
		canSwitchCustomers () {
			return this.$store.state.authentication.userInfo?.organization?.is_security ||
          this.$store.state.authentication.userInfo?.organization?.is_dealer;
		},
		flag () {
			return this.$store.state.filters.flag;
		},
		userProductId () {
			return this.clientProductId.organization.product_id;
		},
		roleUser () {
			return this.authentication ? `${this.authentication?.userInfo?.assignments[0]?.roles?.id}` : null;
			// return this.authentication ? `${this.authentication.userInfo.id}` : null;
		},
		avatartInitials () {
			return this.authentication ? this.authentication?.userInfo?.given_name + ' ' + this.authentication?.userInfo?.surname.charAt(0) : ' ';
		},
		userMenu () {
			if (this.$store.getters['authentication/isVideoUser']) {
				return [
					{
						text: 'Profile',
						action: 'profile',
						icon: 'mdi-account',
					},
					{
						text: 'Console',
						action: 'console',
						icon: 'mdi-tune',
					},
					{
						text: 'Logout',
						action: 'logout',
						icon: 'mdi-logout',
					},
				];
			} else {
				return [
					{
						text: 'Profile',
						action: 'profile',
						icon: 'mdi-account',
					},
					{
						text: 'Logout',
						action: 'logout',
						icon: 'mdi-logout',
					},
				];
			}
		},
	},
	watch: {
		selectedOrganization (val) {
			this.$store.dispatch('filters/updateFiltersClient', val.id);
			localStorage.setItem('organization_id', val.id);
			this.updateSelectedProduct();
		},
		flag (newValue) {
			if (newValue) {
				this.createdClient = localStorage.getItem('organization_id2');
				this.allClients();
			}
		},
		// organizations (val) {
		// 	this.allClients();
		// },
	},
	async created () {
		EventBus.$on('refreshSelect', data => {
			this.allClients();
		});
		EventBus.$on('newClientCreated', this.allClients);
		if (this.userProductId === 3) {
			this.setTheme(SAFE_RESOURCE);
		} else if (this.userProductId === 2) {
			this.setTheme(SAFE_BANKER);
		} else if (this.userProductId === 1) {
			this.setTheme(SAFE_SECURITY);
		}
		this.role();
		await this.allClients();
	},
	mounted () {
		EventBus.$on('newClientCreated', () => {
			this.allClients();
		});
		if (this.userProductId === 3) {
			this.setTheme(SAFE_RESOURCE);
		} else if (this.userProductId === 2) {
			this.setTheme(SAFE_BANKER);
		} else if (this.userProductId === 1) {
			this.setTheme(SAFE_SECURITY);
		}
	},
	destroyed () {
		EventBus.$off('newClientCreated', this.allClients);
	},
	methods: {
		lostFocus (item) {
			if (typeof item === 'object' && item !== null) {
				this.selectedOrganization = item;
			} else {
				this.selectedOrganization = this.previousSelectedOrganization;
			}
			this.previousSelectedOrganization = this.selectedOrganization;
		},
		updateSelectedProduct () {
			this.temp = true;
			const selectedItem = this.organizationName.find(
				(item) => item.id === this.selectedOrganization.id,
			);

			if (selectedItem) {
				this.selectedProduct = selectedItem.product_id;
				this.$store.dispatch('setSelectedProduct', this.selectedProduct);

				if (this.selectedProduct === 3) {
					this.setTheme(SAFE_RESOURCE);
				} else if (this.selectedProduct === 2) {
					this.setTheme(SAFE_BANKER);
				} else if (this.selectedProduct === 1) {
					this.setTheme(SAFE_SECURITY);
				}
			}
		},
		setTheme (theme) {
			this.$vuetify.theme.themes.light = theme;
			this.$vuetify.theme.themes.dark = theme;
		},
		...mapMutations({
			setDrawer: 'SET_DRAWER',
		}),
		role () {
			this.userRole = `${this.authentication.userInfo.given_name}`;
		},
		async allClients () {
			this.organizationName = [];
			try {
				this.organizationName = (await Organization
					.select(['id', 'name', 'product_id'])
					.where({ is_customer: true })
					.get())
					.data;

				if (!this.temp) {
					localStorage.setItem('organization_id', this.organizationName[0].id);
					await this.$store.dispatch('filters/updateFiltersClient', this.organizationName[0].id);
				}

				const clientOrganization = this.organizationName.find(org => org.id === parseInt(this.createdClient));

				if (clientOrganization) {
					await this.$store.dispatch('filters/updateFiltersClient', clientOrganization.id);
					this.selectedOrganization = clientOrganization;
				} else {
					this.selectedOrganization = this.organizationName[0];
				}
			} catch (e) {
				this.$store.commit('alerts/add', { id: 'timerUpdate', type: 'error', message: 'No Client Found' });
				this.$store.commit('alerts/clear', { id: 'timerUpdate', type: 'error', message: 'No Client Found' });
			} finally {
				localStorage.removeItem('organization_id2');
				// this.selectedOrganization.id = parseInt(localStorage.getItem('organization_id'));
			}
			this.filterStartDate = this.startDate;
			this.filterEndDate = this.endDate;
		},
		menuActionClick (action) {
			if (action === 'profile') {
				this.profileDialog = true;
				this.consoleDialog = false;
			} else if (action === 'console') {
				this.consoleDialog = true;
				this.profileDialog = false;
			} else if (action === 'logout') {
				this.$store.dispatch('authentication/doLogout', 'user');
			}
		},
		updateVuex (data) {
			const payload = {
				pickerStart: data[0].format('yyyy-MM-DD'),
				pickerEnd: data[1].format('yyyy-MM-DD'),
			};
			this.$store.dispatch('filters/updateFilters', payload);
		},
	},

};
</script>

<style>
.btn-color{
  background: linear-gradient(#557DBE, #3C5886)
}

.mdrp__activator .activator-wrapper .text-field:focus~label, .mdrp__activator .activator-wrapper .text-field__filled~label {
  display: none !important;
}
.v-select__selections{
  height: 30px;
}
</style>
