import RegionRepository from './v2/Region';
import BranchRepository from './v2/Location';
import HeadOfficeRepository from './v1/Client/HeadOfficeRepository';
import SecurityOfficeRepository from './v1/Client/SecurityOfficeRepository';
import UserRepository from './v2/User';
import OperatorRepository from './v1/Client/OperatorRepository';
import TaskListRepository from './v1/Client/TaskListRepository';
import SurveyRepository from './v2/Survey';
import SecurityOfficerRepository from './v1/Client/SecurityOfficerRepository';
import ChatMessageRepository from './v1/Client/ChatMessageRepository';
import ReportRepository from './v1/Client/ReportRepository';
import NotificationRepository from './v1/Client/NotificationRepository';
import ActiveSessionRepository from './v1/Client/ActiveSessionRepository';
import TaskRepository from './v2/Task';
import VideoRecRepository from './v1/Client/VideoRecRepository';
import SecurityPhraseRepository from './v1/Client/SecurityPhraseRepository';
import SchedulesRepository from './v2/Schedule';

import OrganizationRepository from './v2/Organization';
import OrganizationRepositoryV1 from './v1/Client/OrganizationRepository';
import RegionRepositoryV1 from './v1/Client/RegionRepository';
import DomainRepository from './v2/Domain';
import BranchRepositoryV1 from './v1/Client/BranchRepository';
import TimedSchedule from './v2/TimedSchedule';
import UntimedSchedule from './v2/UntimedSchedule';

import SurveyRepositoryV1 from './v1/Client/SurveyRepository';
import ScheduleRepositoryV1 from './v1/Client/ScheduleRepository';
import UserRepositoryV1 from './v1/Client/UserRepository';
import TaskRepositoryV1 from './v1/Client/TaskRepository';
import GrpcRepository from '@/repositories/v1/Client/GrpcRepository';

const repositories = {
	client: {
		organization: OrganizationRepository,
		organizationV1: OrganizationRepositoryV1,
		domain: DomainRepository,
		region: RegionRepository,
		regionV1: RegionRepositoryV1,
		schedule: SchedulesRepository,
		scheduleV1: ScheduleRepositoryV1,
		timedSchedule: TimedSchedule,
		untimedSchedule: UntimedSchedule,
		headOffice: HeadOfficeRepository,
		branch: BranchRepository,
		branchV1: BranchRepositoryV1,
		securityOffice: SecurityOfficeRepository,
		user: UserRepository,
		userV1: UserRepositoryV1,
		operator: OperatorRepository,
		tasklist: TaskListRepository,
		survey: SurveyRepository,
		surveyV1: SurveyRepositoryV1,
		securityOfficer: SecurityOfficerRepository,
		chatMessage: ChatMessageRepository,
		report: ReportRepository,
		notification: NotificationRepository,
		activeSession: ActiveSessionRepository,
		task: TaskRepository,
		videorec: VideoRecRepository,
		securityPhrase: SecurityPhraseRepository,
		taskV1: TaskRepositoryV1,
		grpc: GrpcRepository,
	},
	common: {},
};

export default {
	get: (type, name) => {
		if (repositories[type][name]) {
			return repositories[type][name];
		} else if (repositories.common[name]) {
			return repositories.common[name];
		} else {
			throw new Error('Invalid Repository Type');
		}
	},
};
