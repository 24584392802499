import RegionalOffice from '@/lib/models/Client/RegionalOffice';
import { RegionalOfficeError } from '@/lib/Errors';
import $http from '../../../plugins/axios';

async function modifyRegion (body, errorMessage) {
	const response = await $http.post('/api/client/manageregionaloffices', body);

	if (response.data?.status === 200) {
		if (response.data?.success) {
			return response.data.body;
		} else return response.data;
	} else {
		throw new RegionalOfficeError(errorMessage);
	}
}

export default {
	async get () {
		const body = {
			case: 'GET-REGIONAL-OFFICES',
		};

		const response = await $http.post('/api/client/getoffices', body);

		if (response.data?.success) {
			const data = response.data.body.organizationobject || [];
			const regionalOffices = data.map(reg => {
				const region = new RegionalOffice(reg);
				return region;
			});
			return regionalOffices;
		} else {
			throw new RegionalOfficeError('Error retrieving Regions');
		}
	},
	async updateRegionDetails (object) {
		object.case = 'UPDATE-REGIONAL-OFFICE-DETAILS';

		return modifyRegion(object, 'Error saving Region Details');
	},
	async updateRegionWeekSchedule (object) {
		object.case = 'UPDATE-REGIONAL-OFFICE-WEEKSCHEDULE';

		return modifyRegion(object, 'Error saving Region Week Schedule');
	},
	async updateRegionHolidays (object) {
		object.case = 'UPDATE-REGIONAL-OFFICE-HOLIDAYS';

		return modifyRegion(object, 'Error saving Region Holidays');
	},
	async updateTimers (object) {
		object.case = 'UPDATE-REGIONAL-OFFICE-TIMERS';

		return modifyRegion(object, 'Error saving Region Default Timers');
	},

	// Storing Client this way, because I could not catch the 422 Unprocessable Entity response using 'vue-api-query'
	async store (body) {
		const response = await $http.post(`/api/organizations/${body.organization_id}/regions`, body);
		if (response.status === 201) {
			return response.data.data;
		} else {
			if (response.response.status === 422) {
				return response.response.data;
			}
			throw new RegionalOfficeError('Error getting branch structure');
		}
	},
	async update (body) {
		const response = await $http.patch(`/api/regions/${body.id}`, body);
		if (response.status === 200) {
			return response.data;
		} else {
			if (response.response.status === 422) {
				return response.response.data;
			}
			throw new RegionalOfficeError('Error getting branch structure');
		}
	},
	async setActiveRegion (object) {
		const response = await $http.patch(`/api/regions/${object}/restore`);
		if (response.status === 200) {
			return response.data;
		} else {
			if (response.response.status === 422) {
				return response.response.data;
			}
			throw new RegionalOfficeError('Error updating location');
		}
	},

	async listForAssignments (orgId) {
		try {
			const response = await $http.get(`/api/regions/listForAssignments/${orgId}`);

			if (response.status === 200) {
				return response.data;
			} else if (response.status === 422) {
				return response.data; // Handle 422 status code differently if needed
			} else {
				throw new Error('Error getting branch structure');
			}
		} catch (error) {
			throw new Error('An error occurred while making the request');
		}
	},
	async delete (body) {
		const response = await $http.delete(`/api/regions/${body.id}`, body);
		if (response.status !== 204) {
			throw new RegionalOfficeError('Error getting branch structure');
		}
	},

	async getSimplifiedRegions (filters) {
		const formattedFilters = Object.entries(filters)
			.map(([key, value]) => `filter[${key}]=${value}`)
			.join('&');

		const response = await $http.get(`/api/regions/getSimplified?${formattedFilters}`);

		if (response.status !== 200) {
			throw new RegionalOfficeError('Error retrieving regions');
		}

		return response.data;
	},
};
