<template>
	<v-app v-if="capabilitiesLoaded">
		<DashboardCoreDrawer />
		<DashboardCoreAppBar />
		<v-main style="background-color: #eee">
			<router-view />
		</v-main>
		<alert-list />
	</v-app>
	<v-app v-else>
		<div class="spinner-container">
			<div class="spinner-frame">
				<div class="spinner-cover"></div>
				<div class="spinner-bar"></div>
			</div>
		</div>
	</v-app>
</template>

<script>
// @ is an alias to /src
import DashboardCoreAppBar from '@/views/core/AppBar';
import DashboardCoreDrawer from '@/views/core/Drawer';
import AlertList from '@/components/base/AlertList';
import { mapGetters } from 'vuex';

export default {
	name: 'Home',
	components: { DashboardCoreAppBar, DashboardCoreDrawer, AlertList },
	computed: {
		...mapGetters({
			capabilitiesLoaded: 'authentication/capabilitiesLoaded',
		}),
	},
	created () {
		// On a new page load, clear out any old alerts from the previous session
		this.$store.commit('alerts/clear');
	},
};
</script>
<style scoped>
#main-app {
	background: linear-gradient(#557DBE, #3C5886);
}
</style>

<style theme="safebanker">
/* #main-app {
	background: #04f;
} */
</style>

<style theme="saferesource">
/* #main-app {
	background: #fa0;
} */
</style>
