
export default {
	namespaced: true,
	state: {
		key: null,
		host: null,
		port: null,
		channels: [],
	},
	mutations: {
		setConfig (state, payload) {
			state.key = payload?.config?.key;
			state.host = payload?.config?.host;
			state.port = payload?.config?.port;
			state.channels = payload?.channels;
		},
	},
};
