import Repository from '@/repositories/RepositoryFactory';

const Survey = Repository.get('client', 'survey');
const SurveyV1 = Repository.get('client', 'surveyV1');

export default {
	namespaced: true,
	state: {
		surveys: [],
		survey: {},
		branchSurvey: {},
		usersToNotify: [],
		usersToSelect: [],
		surveyExternalUser: {},
		externalUsersForSurvey: [],
	},
	getters: {

	},
	mutations: {
		LOAD_SURVEYS: (state, payload) => {
			state.surveys = payload;
		},
		LOAD_SURVEY_BY_ID: (state, payload) => {
			state.survey = payload;
		},
		LOAD_SURVEY_BY_ID_EXTERNAL_USER: (state, payload) => {
			state.surveyExternalUser = payload;
		},
		LOAD_BRANCH_SURVEY: (state, payload) => {
			state.branchSurvey = payload;
		},
		LOAD_USERS_TO_SELECT: (state, payload) => {
			state.usersToSelect = payload;
		},
		LOAD_USERS_TO_NOTIFY: (state, payload) => {
			state.usersToNotify = payload;
		},
	},
	actions: {
		async getAllSurveys ({ commit }, id) {
			commit('LOAD_SURVEYS', await Survey.include('questions.answers',
				'organization',
				'survey_locations.location',
				'survey_locations.users',
				'survey_locations.notify_ids',
				'survey_answers.location',
				'survey_answers.user').where('organization_id', `${id}`).all());
		},

		async getSurveyById ({ commit }, id) {
			commit('LOAD_SURVEY_BY_ID', await Survey.include('survey_locations.location', 'survey_locations.users', 'survey_locations.notify_ids', 'questions.answers', 'user').where('location', 30).find(id));
		},

		async getSurveyExternalUser ({ commit }, object) {
			commit('LOAD_SURVEY_BY_ID_EXTERNAL_USER', await Survey.getSurveyExternalUser(object));
		},

		async getBranchSurvey ({ commit }, id) {
			commit('LOAD_BRANCH_SURVEY', await Survey.find('getSurvey/' + id));
		},

		async storeSurveyV1 ({ commit }, object) {
			const res = await SurveyV1.storeV1(object);
			return res;
		},

		async assignSurvey ({ commit }, object) {
			const res = await SurveyV1.assignSurveyToLocation(object);
			return res;
		},

		async deassignSurvey ({ commit }, object) {
			const response = await SurveyV1.deAssignSurveyFromLocation(object);
			return response;
		},

		async addExternalUsers ({ commit }, users) {
			const response = await Survey.addExternalUsers(users);
			return response;
		},

		async updateListOfExtUsersForSurvey ({ commit }, object) {
			const response = await Survey.updateListOfExtUsersForSurvey(object);
			return response;
		},
		async updateUsersToTakeSurvey ({ commit }, object) {
			const response = await SurveyV1.updateUsersToTakeSurvey(object);
			return response;
		},

		async updateUsersToNotifyForSurvey ({ commit }, object) {
			const response = await SurveyV1.updateUsersToNotifyForSurvey(object);
			return response;
		},

		async updateSurveySchedule ({ commit }, object) {
			const response = await SurveyV1.updateSurveySchedule(object);
			return response;
		},

		async updateSurveyName ({ commit }, object) {
			const model = await Survey.find(object.id);
			model.name = object.name;
			model.patch();
			return model;
		},
		async updateV1 ({ commit }, object) {
			const res = await SurveyV1.update(object);
			return res;
		},
		async getUsersToSelectForSurvey ({ commit }, body) {
			commit('LOAD_USERS_TO_SELECT', await SurveyV1.getUsersToSelectForSurvey(body));
		},
		async getUsersToSelectForNotify ({ commit }, body) {
			commit('LOAD_USERS_TO_NOTIFY', await SurveyV1.getUsersToSelectForNotify(body));
		},
	},
};
