import Error from '@/lib/extendable-error';

export class APIServerError extends Error {
	constructor (message, response) {
		super(message);
		this.response = response;
	}
}

export class ActiveSessionError extends Error { }
export class AddressValidationError extends Error { }
export class AuthenticationError extends Error { }
export class BranchOfficeError extends Error { }
export class ChatMessageError extends Error { }
export class GeoLocationError extends Error { }
export class HeadOfficeError extends Error { }
export class NotificationError extends Error { }
export class OperatorError extends Error { }
export class OrganizationError extends Error { }
export class RegionalOfficeError extends Error { }
export class ReportError extends Error { }
export class RoleError extends Error { }
export class SecurityOfficeError extends Error { }
export class SecurityOfficerError extends Error { }
export class SecurityPhraseError extends Error { }
export class SurveyError extends Error { }
export class TaskError extends Error { }
export class TaskListError extends Error { }
export class UserError extends Error { }
export class RolesPrivilegesError extends Error { }
export class VideoError extends Error { }
export class ScheduleError extends Error { }
export class LocationError extends Error { }
