<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
	:class="[themeClass]"
  >
		<v-overlay
			v-if="!selectedClient"
			activator="parent"
			location-strategy="connected"
		>
		</v-overlay>
	<v-list class="nav-logo">
      <v-list-item class="justify-center">
          <v-img
            v-bind:src="logo.src"
            max-width="140"
            max-height="60"
            class="mt-3 mb-2"
          />
      </v-list-item>
    </v-list>
	<!-- Send ENS -->
	<!-- <v-list v-if="ens">
		<div class="pa-2">
			<v-btn rounded class="black--text" elevation="1" color="secondary" @click="emergencyDialog = true">
			<v-icon left color="error">
			mdi-telegram
			</v-icon>
			{{ $t('sendEns') }}
			</v-btn>
		</div>
    </v-list> -->
	<!-- End Send ENS -->

	<v-list expand class="pa-0">
		<template v-for="(item, i) in menuItems">
			<v-list-group
				v-if="item.children && item.children.length > 0"
				:key="`item-${i}`"
				:active-class="`primary white--text`"
				:value="subIsActive(item.path)"
			>
				<template v-slot:activator>
					<v-list-item :active-class="`primary white--text`">
						<v-icon v-text="item.meta.icon"></v-icon>
						<v-list-item-title class="ml-5">{{ item.name }}</v-list-item-title>
					</v-list-item>
				</template>

				<v-list-item
					v-for="(child, i) in item.children"
					:key="`group-${i}`"
					link
					:to="child"
					:active-class="`primary white--text`"
				>
					<v-icon class="ml-10" v-text="child.meta.icon"></v-icon>
					<v-list-item-title class="ml-5">{{ child.name }}</v-list-item-title>
				</v-list-item>

			</v-list-group>
			<v-list-item v-else link :to="item" :key="`item-${i}`" :active-class="`primary white--text`">
				<v-list-item-icon>
					<v-icon v-text="item.meta.icon"></v-icon>
				</v-list-item-icon>
				<v-list-item-title >{{ item.name }}</v-list-item-title>
			</v-list-item>
		</template>
    </v-list>

	<v-card
		class="mx-auto"
		outlined
		color="blue darken-1"
		v-if="roleId === 12"
	>
		<v-list-item three-line>
			<v-list-item-icon class="mt-9">
				<v-icon>mdi-office-building</v-icon>
			</v-list-item-icon>
			<v-list-item-content>
				<v-list-item-title class="headline mb-1" v-if="selectedOrganisation">
				{{ selectedOrganisation.Name }}
				</v-list-item-title>
				<v-list-item-subtitle class="headline mb-1" v-if="selectedOrganisation">
				{{ selectedOrganisation.PhoneNumber }}
				</v-list-item-subtitle>
				<v-list-item-subtitle v-if="selectedOrganisation">{{ selectedOrganisation.FormatedAddress }}</v-list-item-subtitle>
			</v-list-item-content>
		</v-list-item>
	</v-card>

	<v-list flat>
      <v-list-item-group
		v-if="isSecurity"
        color="primary"
      >
        <v-list-item>
			<chat-list></chat-list>
        </v-list-item>
      </v-list-item-group>
    </v-list>

	<!-- CHECK INTERNET STATUS -->
	<template>
		<vueInternetChecker
			@status="status"
			@event="event"
		/>
    </template>
	<!-- END CHECK INTERNET STATUS -->

	<EmergencyDialog v-if="emergencyDialog" :dialog="emergencyDialog" @emergency-dialog-closed="emergencyDialog=false"></EmergencyDialog>
    <template v-slot:append>
		<div class="mb-1" v-if="ens" style="border-top: 1px solid white; border-bottom: 1px solid white;">
			<v-btn id="ensBtn" text block @click="emergencyDialog = true" class="text-left" style="height: 56px; justify-content: flex-start;">
				<v-icon left color="error" class="ml-3">
					mdi-send
				</v-icon>
				<span class="ml-2">{{ $t('sendEns') }}</span>
			</v-btn>
		</div>
		<div class="">
			<span class="ml-8 mb-5 font-weight-light white--text">Internet status: {{ internetStatus }} </span>
			<Blink v-if="internetStatus === 'Disconnected'"><v-icon color="error" medium>mdi-lan-disconnect</v-icon></Blink>
			<v-icon v-if="internetStatus === 'Connected'" color="green" medium>mdi-lan-connect</v-icon>
		</div>
		<div class="body-2 font-weight-light py-2 text-center secondary--text">
			&copy; {{ new Date().getFullYear() }} SaferMobility, LLC<br>
			<v-icon>mdi-18px mdi-web</v-icon>{{ frontendVersion }}
			<v-icon v-if="androidStoreVersion">mdi-18px mdi-android</v-icon>{{ androidStoreVersion }}
			<v-icon v-if="appleStoreVersion">mdi-18px mdi-apple</v-icon>{{ appleStoreVersion }}
		</div>
    </template>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import {
	mapGetters,
	mapState,
} from 'vuex';
import EmergencyDialog from '@/views/components/EmergencyDialog.vue';
import vueInternetChecker from 'vue-internet-checker';
import Blink from 'vue-blink';
import ChatList from '../components/ChatList.vue';
import { version as frontendVersion } from '../../../package.json';
const { DARK_LOGO } = require(`@/themes/${process.env.VUE_APP_THEME}/image-paths`);

export default {
	name: 'DashboardCoreDrawer',
	components: {
		EmergencyDialog,
		vueInternetChecker,
		Blink,
		ChatList,
	},
	props: {
		expandOnHover: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		emergencyDialog: false,
		onLine: null,
		internetStatus: 'Connected',
		themeClass: '',
		logo: DARK_LOGO,
	}),
	computed: {
		...mapState(['barColor', 'barImage']),
		...mapState({
			roleId: state => state.authentication.roleId,
			userInfos: state => state.authentication.userObject.UserInfos,
			organisations: state => state.organisation.organisations,
			selectedClient: state => state.filters.clientId,
			authentication: state => state.authentication,
			clientProductId: state => state.authentication.userInfo,
		}),
		...mapGetters({
			hasAnyCapability: 'authentication/hasAnyCapability',
			selectedProduct: 'selectedProduct',
		}),
		userProductId () {
			return this.clientProductId.organization.product_id;
		},
		frontendVersion () {
			return frontendVersion;
		},
		androidStoreVersion () {
			const androidVersion = this.getAppVersion?.android_store_version?.split(/[+(]/)[0]?.trim() || '';
			if (androidVersion !== 'UNKNOWN') {
				return androidVersion;
			} else {
				return null;
			}
		},
		appleStoreVersion () {
			const appleVersion = this.getAppVersion?.apple_store_version?.split(/[+(]/)[0]?.trim() || '';
			if (appleVersion !== 'UNKNOWN') {
				return appleVersion;
			} else {
				return null;
			}
		},
		getAppVersion () {
			return this.$store.state.users?.getAppVersion[0] || {};
		},
		isSecurity () {
			return this.hasAnyCapability(['accept#Call']);
		},
		selectedOrganisation () {
			let organization;
			this.organisations.forEach(o => {
				if (o.Id === this.userInfos.OrganizationId) {
					organization = o;
				}
			});
			return organization;
		},
		drawer: {
			get () {
				return this.$store.state.drawer;
			},
			set (val) {
				this.$store.commit('SET_DRAWER', val);
			},
		},
		menuItems () {
			const routes = this.$router.options.routes;
			const menuItems = this.filterMenu(routes.find(r => r.path === '/').children, '/');
			return this.buildMenu(menuItems);
		},
		profile () {
			return {
				avatar: true,
				title: this.$t('avatar'),
			};
		},
		ens () {
			return this.roleId !== 1;
		},
	},
	watch: {
		selectedProduct (selectedProduct) {
			if (selectedProduct === 2) {
				this.themeClass = 'safebanker';
			} else if (selectedProduct === 3) {
				this.themeClass = 'saferesource';
			} else {
				this.themeClass = 'safebanker'; // Default or fallback class
			}
		},
	},
	mounted () {
		if (this.userProductId === 2) {
			this.themeClass = 'safebanker';
		} else if (this.userProductId === 3) {
			this.themeClass = 'saferesource';
		} else {
			this.themeClass = 'saferesource'; // Default or fallback class
		}
		this.fetchLatestAppVersion();
		if (this.roleId === 12) {
			this.getOrganisations();
		}
	},
	methods: {
		fetchLatestAppVersion () {
			this.$store.dispatch('users/getLatestAppVersion');
		},
		status (ele) {
			this.onLine = ele;
		},
		event (ele) {
			this.internetStatus = ele.type === 'online' ? 'Connected' : 'Disconnected';
		},
		getOrganisations () {
			this.$store.dispatch('organisation/get');
		},
		/**
		 * Get all the menu items from the router
		 */
		filterMenu (items, parentPath) {
			const allItems = items.filter(r => this.hasAnyCapability(r.meta?.caps))
				.flatMap(r => {
					if (r.meta?.menu !== true) {
						return [];
					}
					const path = r.path.startsWith('/') ? r.path : parentPath + r.path;
					const children = (r.children?.length > 0) ? this.filterMenu(r.children, path + '/') : [];
					return [
						...children,
						{
							name: r.name,
							meta: r.meta,
							path,
						},
					];
				});
			const { compare } = Intl.Collator('en-US');
			allItems.sort((a, b) => compare(a.path, b.path));

			return allItems;
		},
		/**
		 * Build a sorted nested array of the menu items
		 *
		 * Based on https://stackoverflow.com/a/62153395/829970
		 */
		buildMenu (items) {
			const index = items.reduce((a, r) => {
				a[r.path] = r;
				return a;
			}, {});

			const menu = items.reduce((root, { path }) => {
				const route = index[path];
				const parent = index[path.split('/').slice(0, -1).join('/')] || root;
				parent.children = [...(parent.children || []), route];
				return root;
			}, { children: [] });

			const { compare } = Intl.Collator('en-US');
			const sorter = (items) => {
				items.sort((a, b) => compare(a.meta.order, b.meta.order));
				items.forEach(i => { if (i.children?.length > 0) { sorter(i.children); } });
			};
			sorter(menu.children);

			return menu.children;
		},
		subIsActive (parentPath) {
			return this.$route.path.startsWith(parentPath + '/');
		},
	},
};
</script>

<style src="@/sass/styles.css"></style>
<style scoped>
.v-navigation-drawer__content .v-list.nav-logo {
	height: 75px;
	padding: 0;
}
</style>
<!--<style lang="sass">-->
<!--  @import '~vuetify/src/styles/tools/_rtl.sass'-->

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px

</style>
