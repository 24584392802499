import { createPromiseClient } from '@bufbuild/connect';
import { createConnectTransport } from '@bufbuild/connect-web';

import { EventService } from '@core-components/proto-api/live/v1/events_service_connect';
import { StatusRequest, StatusResponse, StartRequest, StartResponse, UpdateRequest, UpdateResponse } from '@core-components/proto-api/live/v1/events_service_pb.js';
import { Timestamp } from '@bufbuild/protobuf';

export default {
	async startAction (object) {
		// FIXME: use the base url from .env
		const transport1 = createConnectTransport({
			baseUrl: object.live,
			credentials: 'include',
			useBinaryFormat: false,
			httpVersion: '1.1',
		});

		const clientLive = createPromiseClient(EventService, transport1);

		const request = new StatusRequest({
			nowOnDevice: Timestamp.now(),
			lookupKey: {
				case: 'locationId',
				value: object.branch_id,
			},
		});
		const response = await clientLive.status(request);
		// eslint-disable-next-line no-new
		new StatusResponse(response);
		const startRequest = new StartRequest({
			locationId: object.branch_id,
			taskId: object.task_id,
			nowOnDevice: Timestamp.now(),
			override: true,
		});
		return new StartResponse(await clientLive.start(startRequest));
	},
	async clearAlarm (object) {
		const transport1 = createConnectTransport({
			baseUrl: object.live,
			credentials: 'include',
			useBinaryFormat: false,
			httpVersion: '1.1',
		});

		const clientLive = createPromiseClient(EventService, transport1);

		const stopRequest = new UpdateRequest({
			correlationId: object.correlation_id,
			nowOnDevice: Timestamp.now(),
			action: {
				case: 'closeout',
				value: {
					closeoutCategory: object.closeout_category,
					closeoutText: object.closeout_text,
				},
			},
		});
		return new UpdateResponse(await clientLive.update(stopRequest));
	},

};
