import Address from './Address';
import Domain from './Domain';
import MessageTemplate from './MessageTemplate';
import Model from './Model';
import Phone from './Phone';
import User from './User';

export default class Organization extends Model {
	// Set the resource route of the model
	resource () {
		return 'organizations';
	}

	domains () {
		return this.hasMany(Domain);
	}

	messageTemplates () {
		return this.hasMany(MessageTemplate);
	}

	relations () {
		return {
			phones: Phone,
			addresses: Address,
		};
	}

	users () {
		return this.hasMany(User);
	}
}
